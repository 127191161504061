* {
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.check-container {
  display: block;
  position: relative;
  /*padding-left: 30px;*/
  padding-top: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/*.checkmark,*/
/*.checkmark-radio {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  height: 25px;*/
/*  width: 25px;*/
/*  background-color: #e9ecef;*/
/*  -webkit-border-radius: 3px;*/
/*  -moz-border-radius: 3px;*/
/*  -ms-border-radius: 3px;*/
/*  border-radius: 3px;*/
/*}*/

.live-chat-loader-placeholder{
  display: none !important;
}
