/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary:  #f59e27;
    --secondary: #ed1c3f;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    /* --dark: #343a40; */
    --dark: #1b2733;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*:hover,
*::before,
*::after {
  box-sizing: border-box;
  text-decoration: none;
}

html {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

.grid-container {
  display: grid;
  /*//grid-template-columns: auto auto;*/
  grid-column-gap: 150px;
  background-color: #dae0e5;
  grid-template-columns: repeat(2, 350px);
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 20px;
  margin: 20px;
  font-size: 30px;
}

.mobile-large-font {
  font-size: 1rem;
  color: #ed1c3f;
}

.btn-floating{
  position: absolute;
  right: 1rem;
  /*bottom: 0;*/
  top: 0;
}

.variant-details{
  margin-top: 1rem;
  margin-bottom: 3rem;
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

}



.price-details{
  position: absolute;
  bottom: 1rem;
}

.deleteButton{
  width:7%;
  height:20%;
  border:hidden;
  background:transparent;
}



header {
  backdrop-filter: blur(5px);
  box-shadow: 1px 1px 1px #33333333;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #ed1c3f;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #af0e29;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 95%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.header-nav-menu .dropdown .dropdown-menu .row{
    flex-wrap:nowrap;
    margin-left: -15px;
    margin-right: 0px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-1-3, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-1-5, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  /*margin-left: auto;*/
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fabfc9;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f6899b;
}

.table-hover .table-primary:hover {
  background-color: #f8a7b5;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #f8a7b5;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fce4c3;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #facd8f;
}

.table-hover .table-secondary:hover {
  background-color: #fbd9ab;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #fbd9ab;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f692a3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 63, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #ed1c3f;
  border-color: #ed1c3f;
}

.btn-primary:hover {
  color: #fff;
  background-color: #d21131;
  border-color: #c6102e;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #d21131;
  border-color: #c6102e;
  box-shadow: 0 0 0 0.2rem rgba(240, 62, 92, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #ed1c3f;
  border-color: #ed1c3f;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #c6102e;
  border-color: #ba0f2c;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 62, 92, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #f59e27;
  border-color: #f59e27;
}

.btn-secondary:hover {
  color: #212529;
  background-color: #eb8c0b;
  border-color: #df850a;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #eb8c0b;
  border-color: #df850a;
  box-shadow: 0 0 0 0.2rem rgba(213, 140, 39, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #f59e27;
  border-color: #f59e27;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #df850a;
  border-color: #d37e0a;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 140, 39, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #ed1c3f;
  border-color: #ed1c3f;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #ed1c3f;
  border-color: #ed1c3f;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 63, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #ed1c3f;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ed1c3f;
  border-color: #ed1c3f;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 63, 0.5);
}

.btn-outline-secondary {
  color: #f59e27;
  border-color: #f59e27;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #f59e27;
  border-color: #f59e27;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 158, 39, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #f59e27;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #f59e27;
  border-color: #f59e27;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 158, 39, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #ed1c3f;
  text-decoration: none;
  cursor: pointer;
}

.btn-link:hover {
  color: #af0e29;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  /* min-width: 16.5rem;
  max-width: 49.5rem; */
  font-size: .875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  overflow-x: auto;

}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
  box-shadow: 1px 1px 3px 0px #757575;
  border-radius: 5px;
  margin-top: 1em;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ed1c3f;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show{
    display: none;

}


.multi-col.show::after{
    min-width: 49.5rem;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ed1c3f;
  background-color: #ed1c3f;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 63, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f692a3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fac1cb;
  border-color: #fac1cb;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ed1c3f;
  background-color: #ed1c3f;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(237, 28, 63, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(237, 28, 63, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(237, 28, 63, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(237, 28, 63, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #f692a3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 63, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #f692a3;
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 63, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.checkout-file-input[disabled] ~ .checkout-file-label,
.checkout-file-input:disabled ~ .checkout-file-label {
  background-color: #e9ecef;
}

.checkout-file-input[disabled] ~ .checkout-file-label::after,
.checkout-file-input:disabled ~ .checkout-file-label::after {
  background-color: #f8e4c6;
}

.checkout-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 8px);
  margin: 0;
  opacity: 0;
}

.checkout-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 8px);
  padding: 0.5rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 2px dashed #8A8B8C;
  border-radius: 4px;
}

.checkout-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  content: "Upload";
  background-color: #F7C070;
  border-radius: 4px;
  color: white;
  margin: 2px;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(237, 28, 63, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(237, 28, 63, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(237, 28, 63, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ed1c3f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #fac1cb;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ed1c3f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #fac1cb;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #ed1c3f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #fac1cb;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ed1c3f;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-icon {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: center;
}

.card-icon i {
  width: 1rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color:transparent !important;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ed1c3f;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #af0e29;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 63, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ed1c3f;
  border-color: #ed1c3f;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #ed1c3f;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #c6102e;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 63, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #f59e27;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #df850a;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 158, 39, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #7b0f21;
  background-color: #fbd2d9;
  border-color: #fabfc9;
}

.alert-primary hr {
  border-top-color: #f8a7b5;
}

.alert-primary .alert-link {
  color: #4e0915;
}

.alert-secondary {
  color: #7f5214;
  background-color: #fdecd4;
  border-color: #fce4c3;
}

.alert-secondary hr {
  border-top-color: #fbd9ab;
}

.alert-secondary .alert-link {
  color: #53360d;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ed1c3f;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ed1c3f;
  border-color: #ed1c3f;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #7b0f21;
  background-color: #fabfc9;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #7b0f21;
  background-color: #f8a7b5;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #7b0f21;
  border-color: #7b0f21;
}

.list-group-item-secondary {
  color: #7f5214;
  background-color: #fce4c3;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7f5214;
  background-color: #fbd9ab;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #7f5214;
  border-color: #7f5214;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  /*height:55%;*/
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.carousel-item img {
  object-fit: contain;
  height: auto !important;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 1.0;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%6c757d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%6c757d' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 50px;
  height: 5px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  /*background-color: #271B75;*/
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #ed1c3f !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #c6102e !important;
}

.bg-secondary {
  background-color: #f59e27 !important;
}
.bg-background{
  background-color: white;
  color: #262626;
  border-radius: 2px;
  border: 1px solid gray;
  /*box-shadow: -2px -2px 0px 0px grey inset*/
}

.bg-background:focus{
  background-color: white;
  color: black;
  /*box-shadow: -2px -2px 0px 0px grey inset*/
}


a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #df850a !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ed1c3f !important;
}

.border-secondary {
  border-color: #f59e27 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;

}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}


.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.absolute-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

@media (max-width: 991.98px) {
  .fixed-top-on-mobile {
    position: fixed!important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .absolute-top-on-mobile {
    position: absolute!important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .hide-on-mobile {
    display: none!important;
  }
}

@media (min-width: 991.99px) {
  .hide-on-desktop {
    display: none!important;
  }
}

@media (min-width: 768px) {
  .hide-md {
    display: none!important;
  }
}

@media (max-width: 767px) {
  .show-md {
    display: none!important;
  }
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-3 {
  width: 3% !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}


.w-75 {
  width: 75% !important;
}

.w-122 {
  width: 122% !important;
}

.w-150 {
  width: 150% !important;
}
.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.ml-05,
.mx-05 {
  margin-left: 0.05rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

@media screen and (max-width: 400px) {
  .ml-5,
  .mx-5 {
    margin-left: 1rem !important;
  }
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pt-promotions,
.py-promotions {
  padding-top: 2.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pb-result,
.py-result {
  padding-bottom: 1rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #ed1c3f !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #af0e29 !important;
}

.text-secondary {
  color: #f59e27 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #c67609 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  /*font: 0/0 a;*/
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*Border radius css generate here*/
/*Transition css generate here*/
.btn {
  font-size: 0.80rem;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  font-weight: bold;
  padding: 0.75rem 0.75rem;
}

.btn .main-logo {
  height: 1.2rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.35rem 0.6rem !important;
}

.btn-rounded {
  -webkit-border-radius: 50rem;
  -moz-border-radius: 50rem;
  -ms-border-radius: 50rem;
  border-radius: 50rem;
}

.btn-icon {
  display: inline-block;
  width: 7rem;
  height: 7rem;
  /*padding: .6rem 0.6rem;*/
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  /*-webkit-border-radius: 50rem;*/
  /*-moz-border-radius: 50rem;*/
  /*-ms-border-radius: 50rem;*/
  /*border-radius: 50rem;*/
  text-align: center;
  cursor: pointer;
}

.btn-icon i {
  width: .7rem;
}

.btn-icon_sm {
  width: 2.5rem;
  height: 2.5rem;
  padding: .65rem .5rem;
}

.minus-quantity-btn-icon_sm{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.plus-quantity-btn-icon_sm{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.count-update-icon{
  font-size: 14px;
}

.minus-quantity-checkout-btn-icon_sm{
  width: 2.10rem;
  height: 2.10rem;
  padding: .65rem .5rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.plus-quantity-checkout-btn-icon_sm{
  width: 2.10rem;
  height: 2.10rem;
  padding: .65rem .5rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cart-item-details{
  justify-content: space-between;
}

/*@media (max-width: 1160px) {*/
@media (max-width: 1200px) {
  .count-update-icon{
    font-size: 8px;
  }
  .count-update{
    width: 2rem;
    height: 2rem;
    padding: .5rem .5rem;
  }
  .cart-item-details{
    justify-content: normal;
  }
  .count-update-icon{
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .count-update-icon{
    font-size: 8px;
  }
  .count-update{
    width: 1.5rem;
    height: 1.5rem;
    padding: .1rem .4rem;
  }
  .minus-quantity-checkout-btn-icon_sm{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .plus-quantity-checkout-btn-icon_sm{

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}


@media (max-width: 575px) {
  .count-update-icon{
    font-size: 10px;
  }
  .count-update{
    width: 1.9rem;
    height: 1.9rem;
    padding: .3rem .4rem;
  }
  .minus-quantity-checkout-btn-icon_sm{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .plus-quantity-checkout-btn-icon_sm{

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}


/* .btn-icon:hover {
  color: #ed1c3f !important;
} */

.card .btn-icon {
  background-color: rgba(39, 27, 117, 0.8);
}

.btn-text {
  display: inline-block;
  color: #fff;
  cursor: pointer;
}

.btn-text .btn-text__icon {
  display: inline-block;
  width: 2.7rem;
  height: 2.7rem;
  padding: .8rem 0.8rem;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 50rem;
  -moz-border-radius: 50rem;
  -ms-border-radius: 50rem;
  border-radius: 50rem;
  text-align: center;
  margin-right: .5rem;
}

.btn-text .btn-text__icon i {
  width: .7rem;
}

.btn-text .btn-text__text {
  display: inline-block;
}

.btn-text:hover, .btn-link:hover {
  text-decoration: none;
  color: #271B75;
}

.btn-light:hover {
  color: #271B75;
}

.btn-group-toggle label {
  margin-right: .3rem;
}

.btn-group-toggle .btn-light.active {
  background-color: #271B75 !important;
  border-color: #271B75 !important;
  color: #fff !important;
}

.radio-square .btn {
  padding: 1.2rem;
  border: 3px solid transparent;
}

.radio-square .btn.active {
  border-color: #271B75 !important;
}

/*Toggle Button*/
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dee2e6;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 1px;
  bottom: 1px;
  background: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

.navbar .slider:before {
  /*background: url("./../images/top-menu/moon-icon.png") no-repeat center;*/
  background-size: cover;
}

input:checked + .slider {
  background-color: #271B75;
}

input:focus + .slider {
  box-shadow: 0 0 1px #271B75;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.slider.round {
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  -ms-border-radius: 34px;
  border-radius: 34px;
}

.slider.round:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.featured-text{
  font-size: 20px;
}
.category-text{
  font-size: 20px;

}

/* The container */
.check-container{
  display: block;
  position: relative;
  /*padding-left: 30px;*/
  padding-top: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  border-radius: 8px;

}
.card-pay-container{
  display: block;
  position: relative;
  padding-left: 20px;
  padding-top: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*overflow: hidden;*/
  border-radius: 8px;

}

.check-container img {
  transition: transform 0.5s ease-in-out;
  border-radius: 7px;

}

.check-container:hover img {
  transform: scale(1.2);
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.card-pay-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.card-pay-container .checkmark-radio{
  position: absolute;
  top: 6px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #e9ecef;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.checkmark,
.checkmark-radio {
  position: absolute;
  top: 12px;
  right: 10px;
  height: 25px;
  width: 25px;
  background-color: #e9ecef;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.check-container.star .checkmark {
  background-color: transparent;
  background-image: url("../images/star-inactive.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.checkmark-radio {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}
.card-pay-container .checkmark-radio {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}

.check-container.thumbs-up .checkmark-radio {
  background-color: transparent;
  background-image: url("../images/thumbs-up.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.check-container.thumbs-down .checkmark-radio {
  background-color: transparent;
  background-image: url("../images/thumbs-down.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.check-container.square .checkmark-radio {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark,
.check-container:hover input ~ .checkmark-radio {
  background-color: #ccc;
}

.check-container.star:hover input ~ .checkmark,
.check-container.thumbs-up:hover input ~ .checkmark-radio,
.check-container.thumbs-down:hover input ~ .checkmark-radio {
  background-color: transparent;
}

.check-container:hover input ~ .checkmark-radio {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}

.check-container.square:hover input ~ .checkmark-radio {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark,
.check-container input:checked ~ .checkmark-radio {
  background-color: #f59e27;
}
.card-pay-container  input:checked ~ .checkmark-radio{
  background-color: #ff3d3d;
}

.check-container.star input:checked ~ .checkmark,
.check-container.thumbs-up input:checked ~ .checkmark-radio,
.check-container.thumbs-down input:checked ~ .checkmark-radio {
  background-color: transparent;
}

.check-container input:disabled ~ .checkmark,
.check-container input:disabled ~ .checkmark-radio {
  background-color: #ced4da;
  cursor: not-allowed;
}

.card-pay-container input:disabled ~ .checkmark-radio {
  background-color: #ced4da;
  cursor: not-allowed;
}

.check-container input:checked ~ .checkmark-radio {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}

.card-pay-container input:checked ~ .checkmark-radio {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}

.check-container.square input:checked ~ .checkmark-radio {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,
.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

.card-pay-container .checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after,
.check-container input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-container .checkmark-radio:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.card-pay-container .checkmark-radio:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.check-container.star .checkmark:after {
  left: 0;
  top: 0;
  border-color: transparent;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  background-image: url("../images/star-active.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  width: 1.625rem;
  height: 1.625rem;
}

.check-container.thumbs-up .checkmark-radio:after {
  left: 0;
  top: 0;
  border-color: transparent;
  background-image: url("../images/thumbs-up-fill.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  width: 1.625rem;
  height: 1.625rem;
}

.check-container.thumbs-down .checkmark-radio:after {
  left: 0;
  top: 0;
  border-color: transparent;
  background-image: url("../images/thumbs-down-fill.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  width: 1.625rem;
  height: 1.625rem;
}

.check-container__image .checkmark,
.check-container__image .checkmark-radio {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  height: 40px;
  width: 40px;
}

.check-container__image .checkmark:after,
.check-container__image .checkmark-radio:after {
  left: 0;
  top: 0;
  border-color: transparent;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  width: 40px;
  height: 40px;
}

.check-container.smile-1 .checkmark-radio {
  /*background-image: url("../images/smile-1-inactive.png");*/
}

.check-container.smile-2 .checkmark-radio {
  /*background-image: url("../images/smile-2-inactive.png");*/
}

.check-container.smile-3 .checkmark-radio {
  /*background-image: url("../images/smile-3-inactive.png");*/
}

.check-container.smile-4 .checkmark-radio {
  /*background-image: url("../images/smile-4-inactive.png");*/
}

.check-container.smile-5 .checkmark-radio {
  /*background-image: url("../images/smile-5-inactive.png");*/
}

.check-container.smile-1:hover input ~ .checkmark-radio {
  /*background-image: url("../images/smile-1-active.png");*/
}

.check-container.smile-2:hover input ~ .checkmark-radio {
  /*background-image: url("../images/smile-2-active.png");*/
}

.check-container.smile-3:hover input ~ .checkmark-radio {
  /*background-image: url("../images/smile-3-active.png");*/
}

.check-container.smile-4:hover input ~ .checkmark-radio {
  /*background-image: url("../images/smile-4-active.png");*/
}

.check-container.smile-5:hover input ~ .checkmark-radio {
  /*background-image: url("../images/smile-5-active.png");*/
}

.check-container.smile-1 .checkmark-radio:after {
  /*background-image: url("../images/smile-1-active.png");*/
}

.check-container.smile-2 .checkmark-radio:after {
  /*background-image: url("../images/smile-2-active.png");*/
}

.check-container.smile-3 .checkmark-radio:after {
  /*background-image: url("../images/smile-3-active.png");*/
}

.check-container.smile-4 .checkmark-radio:after {
  /*background-image: url("../images/smile-4-active.png");*/
}

.check-container.smile-5 .checkmark-radio:after {
  /*background-image: url("../images/smile-5-active.png");*/
}

.star-rating {
  font-size: 0;
  white-space: nowrap;
  display: inline-block;
  height: 24px;
  overflow: hidden;
  position: relative;
  background: url("../images/star-inactive.png");
  background-size: contain;
}

.star-rating i {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
  background: url("../images/star-active.png");
  background-size: contain;
}

.star-rating input {
  -moz-appearance: none;
  -webkit-appearance: none;
  opacity: 0;
  display: inline-block;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 2;
  position: relative;
}

.star-rating input:hover + i,
.star-rating input:checked + i {
  opacity: 1;
}

.star-rating i ~ i {
  width: 20%;
}

.star-rating i ~ i ~ i {
  width: 30%;
}

.star-rating i ~ i ~ i ~ i {
  width: 40%;
}

.star-rating i ~ i ~ i ~ i ~ i {
  width: 50%;
}

::after,
::before {
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
}

.star-rating.star-5 {
  width: 120px;
}

.star-rating.star-5 input,
.star-rating.star-5 i {
  width: 20%;
}

.star-rating.star-5 i ~ i {
  width: 40%;
}

.star-rating.star-5 i ~ i ~ i {
  width: 60%;
}

.star-rating.star-5 i ~ i ~ i ~ i {
  width: 80%;
}

.star-rating.star-5 i ~ i ~ i ~ i ~ i {
  width: 100%;
}

.card {
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  -moz-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.card .card-footer .btn {
  margin-left: 1rem;
}


body.dark-theme .checkmark,
body.dark-theme .checkmark-radio {
  background-color: #212529;
}

body.dark-theme .card .checkmark,
body.dark-theme .card .checkmark-radio {
  background-color: #343a40;
}

body.dark-theme .btn-light {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

body.dark-theme .btn-light:hover {
  color: #271B75;
}

html {
  height: 100% !important;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  position: relative;
  background-color: #e9ecef;
  color: #212529;
  height: 100% !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.footer {
  padding: 5rem 0 0 0;
  background-color: #343a40;
}

.footer .footer-sub-footer {
  /*margin-top: 2rem;*/
  padding: 0.65rem 0;
  border-top: 1px solid #212529;
}

.footer .footer-sub-footer a {
  color: #E6202F;
}

.footer-developer-info {
  font-size: 0.865rem;
}

@media (max-width: 768px) {
  .footer-developer-info {
    font-size: 0.625rem !important;
  }
}


.footer-logo-container {
  min-height: 3rem;
}

.footer-content-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-title {
  font-size: 1rem;
  margin-bottom: 0.6rem;
  font-weight: 600;
  text-align: left;
}

.footer-text {
  font-size: 0.865rem;
  /*margin-bottom: 0.625rem;*/
  margin-bottom: 0.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
}

@media (max-width: 991px) {
  .footer-content-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-title {
    text-align: center;
  }

  .footer-text {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer-title {
    font-size: 0.875rem !important;
    font-weight: 600;
    text-align: center;
  }

  .footer-text {
    font-size: 0.625rem !important;
    text-align: center;
  }
}

.footer-section1-description{
  width: 100%;
}

@media (max-width: 991px){
  .footer-section1-description{
    justify-content: center;
  }
}

.footer-appigo-logo {
  max-width: 100%;
  height: auto;
  background-color: transparent;
}

.contact-section {
  display: block;
}

@media (max-width: 991px) {
  .contact-section{
    display: none;
  }
}

@media (min-width: 996px) and (max-width: 1200px) {
  .contact-section {
    width: 75%;
  }
}

@media (min-width: 1201px) {
  .contact-section {
    width: 50%;
  }
}

.footer-section234-content{
}

@media (max-width: 991px) and (min-width: 0px) {
  .footer-section234-content {
    display: none !important;
  }
}


@media (max-width: 991px) {
  .footer-contact-content-section {
    display: flex; /* Or whatever display style you need */
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .footer-contact-content-section {
    display: none;
  }
}

@media (max-width: 991px) and (min-width: 515px) {
  .footer-contact-content {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 514px) {
  .footer-contact-content {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 991px) and (min-width: 515px) {
  .footer-phone-content {
    margin-left: 3rem!important;
    margin-right: 3rem!important;
  }
}

@media (max-width: 514px) {
  .footer-phone-content {
    margin-left: 0 !important;

  }
}


@media (max-width: 991px) {
  .footer-section234-content{
    margin-bottom: 0rem !important;
    width: 100%;
  }
}

a {
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

a:hover {
  color: #271B75;
}

.form-control {
  font-size: .75rem !important;
  min-height: 3rem;
  border: 0;
  border: 1px solid #ced4da;
}

.mobile-main-search .form-control {
  background-color: #fff;
}

.form-control:focus {
  border-color: #271B75;
  -webkit-box-shadow: 0 0 0 0.2rem #271B75;
  -moz-box-shadow: 0 0 0 0.2rem #271B75;
  box-shadow: 0 0 0 0.05rem #271B75;
}

.form-control:disabled {
  background-color: #ced4da;
  cursor: not-allowed;
}

.form-control:read-only {
  background-color: #ced4da;
}

.input-group-text {
  font-size: .75rem !important;
  border: 0;
  background-color: #fff;
}

.input-group__quantity input {
  width: 2.5rem;
  text-align: center;
}
.sidebar-list-mobile{
  background: white;
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  margin-bottom: 30px;

}

.text-link {
  color: #212529;
  border-radius: 0;
  /*background: red;*/
  padding: 15px;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  /*background: red;*/
}

.text-link:hover {
  text-decoration: none !important;
}

.text-light {
  color: #adb5bd !important;
}

.long-text {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.white-bg {
  background-color: #fff;
}

.bg-facebook, .bg-facebook:hover {
  background-color: #3b5998 !important;
  color: #fff !important;
}

.bg-whatsapp, .bg-whatsapp:hover {
  background-color: #25d366 !important;
  color: #fff !important;
}

.bg-google, .bg-google:hover {
  background-color: #ea4335 !important;
  color: #fff !important;
}

.bg-google, .bg-twitter:hover {
  background-color: #1da1f2 !important;
  color: #fff !important;
}

.bg-linkedin, .bg-linkedin:hover {
  background-color: #0077b5 !important;
  color: #fff !important;
}

.bg-instagram, .bg-instagram:hover {
  background-color: #e1306c !important;
  color: #fff !important;
}

.bg-pinterest, .bg-pinterest:hover {
  background-color: #e60023 !important;
  color: #fff !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu.account-dropdown {
    overflow-x: hidden ;
    max-width: 8rem;
}

.dropdown-menu.account-dropdown a:hover {
    color: var(--primary);
    background-color: var(--dark);
}
.dropdown-menu .dropdown-item {
    width: auto;
    max-width: 100%;
    padding: 0.25rem .8rem;
    white-space: nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item.active {
    color: #271B75;
    /*background-color: #271B75;*/
}

.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
    /*color: #271B75;*/
    background-color: transparent;
}

.dropdown-menu__scrollable {
    height: 25rem;
    overflow: auto;
}

.common-list {
  padding: 0;
  margin: 0;
}

.common-list li {
  list-style: none;
}

.flex-shrink-0 {
  padding: 2.5rem 0 0 0 !important;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.content-block__empty-row {
  min-height: 65vh;
  background-color: #fff;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  -moz-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.main-container {
  padding-left: 14rem;
  padding-right: 14rem;
}

.pagination .page-item .page-link {
  color: #212529;
}

.pagination .page-item.active .page-link {
  background-color: #271B75;
  border-color: #271B75;
  color: #fff;
}

.badge {
  font-size: 100%;
  padding: .1rem 0.6em;
  font-weight: normal;
  min-width: 5rem;
  line-height: 1.5;
}

.badge-warning {
  background-color: #ff9f43;
  color: #fff;
}

.badge-danger {
  background-color: #ff6b6b;
}

.user-profile-image {
  width: 2rem;
  height: 2rem;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  border-radius: 10rem;
}

.user-profile-image-large {
  width: 6rem;
  height: 6rem;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  border-radius: 10rem;
}

.card .input-group-text {
  background-color: #e9ecef;
}

.main-banner-area-small {
  margin-top: 8.5rem;
}

.main-banner-area-small .carousel .carousel-inner .carousel-item .carousel-caption {
  top: 17% !important;
}

.main-banner-area-small .carousel .carousel-inner .carousel-item .carousel-caption .main-text {
  font-size: 3rem !important;
}

.main-section {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.main-section .product-slider-section {
  position: relative;
  padding: 3rem 3rem 2rem 3rem;
}

.main-section .carousel .card .products-section__image .products-section__offer-bottom {
  background-color: #f8f9fa;
}
.product-image {
  transition: opacity 0.75s ease-in-out; /* Adjust timing as needed */
  opacity: 1;
}

.product-image.fade {
  opacity: 0;
}


.products-section__image .zoom-image {
  transition: transform 0.5s ease-in-out;
}

.products-section__image:hover .zoom-image {
  transform: scale(1.2);
}
.main-section .carousel .carousel-indicators {
  bottom: -2.6rem;
}

/* .main-section .carousel .carousel-indicators li {
  height: 1rem;
  width: 1rem;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  border-radius: 10rem;
  border-top-width: 0;
  border-bottom-width: 0;
} */

.main-section .carousel .carousel-indicators li.active {
  background-color: #271B75;
}

.main-section .carousel .carousel-control-prev {
  /* width: 5%; */
  left: -5rem;
}

.main-section .carousel .carousel-control-next {
  /* width: 5%; */
  right: -5rem;
}

.main-section .carousel .carousel-control-prev,
.main-section .carousel .carousel-control-next {
  color: #6c757d;
}

.main-section .carousel .carousel-control-prev:hover,
.main-section .carousel .carousel-control-next:hover {
  /* color: #271B75; */
  opacity: 0.7;
}

.main-section .checkout__header.active .checkout__header-icon {
  color: #271B75;
}

.main-section .checkout__header.inactive .checkout__header-icon {
  color: #adb5bd;
}

.main-banner-area .carousel .carousel-indicators li {
  height: 1rem;
  width: 1rem;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  border-radius: 10rem;
  border-top-width: 0;
  border-bottom-width: 0;
}

.main-banner-area .carousel .carousel-indicators li.active {
  background-color: #271B75;
}

.main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption {
  left: 10%;
  right: 40%;
  top: 33%;
  text-align: left;
}

.main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption .main-text {
  font-size: 4rem;
}

.main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption .carousel-caption__price-section {
  margin-top: 2rem;
  position: absolute;
  /*left: 0;*/
  width: 40%;
}

.main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption .carousel-caption__price-section .carousel-caption__price-section-crop-txt {
  text-decoration: line-through;
}

.main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption .carousel-caption__main-btn {
  text-transform: uppercase;
  background-color: #6c757d;
  border: 2px solid #000;
}

.products-section .card {
  margin-bottom: 1rem;
  /*aspect-ratio: 1/1;*/
}

.products-section .card .products-section__image {
  position: relative;
  margin-bottom: 4rem;
}

.products-section .card .products-section__image .products-section__offer-top {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #271B75;
  padding: 1rem;
  -webkit-border-top-left-radius: 0.25rem;
  -moz-border-top-left-radius: 0.25rem;
  -ms-border-top-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.products-section .card .products-section__image .products-section__offer-bottom {
  position: absolute;
  /*bottom: -4rem;*/
  left: 0;
  right: 0;
  background-color: #fff;
  color: #271B75;
  /*min-height: 7.5rem;*/
  padding: 1.5rem 1rem 1rem 1rem;
  -webkit-border-bottom-left-radius: 0.25rem;
  -moz-border-bottom-left-radius: 0.25rem;
  -ms-border-bottom-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  -webkit-border-bottom-right-radius: 0.25rem;
  -moz-border-bottom-right-radius: 0.25rem;
  -ms-border-bottom-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.products-section .card .products-section__image .products-section__offer-bottom .products-section__offer-bottom-actions {
  position: absolute;
  top: 94%;
  left: 0;
  right: 0;
}

.products-section .card .products-section__image .products-section__offer-bottom-actions {
  position: absolute;
  top: 94%;
  left: 0;
  right: 0;
}

.products-section .card .products-section__image .products-section__offer-bottom .products-section__offer-bottom-actions .btn.active {
  color: #271B75 !important;
}

.products-section .card .products-section__image .products-section__offer-bottom .products-section__product-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
  padding: .3rem 0;
  display: block;
}

.products-section .card .products-section__image .products-section__offer-bottom .products-section__product-name:hover {
  color: #271B75;
  text-decoration: none;
}

.products-section .card .products-section__empty-card {
  min-height: 20.6rem;
  padding: 9.47rem 4rem;
}

.products-section.promote-products .products-section__image {
  margin-bottom: 0;
}

.products-section.promote-products .products-section__image .products-section__offer-bottom {
  bottom: 0;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 3px solid transparent;
}

.nav-tabs .nav-link.active {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 3px solid #271B75;
}

.nav-tabs .nav-item.show .nav-link {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 3px solid #271B75;
}

.image-nav-tabs .nav-item {
  margin: .2rem .2rem 0 0;
}

.image-nav-tabs .nav-item .nav-link {
  padding: 0;
  border: 3px solid transparent;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  border-radius: 0.3rem;
}

.image-nav-tabs .nav-item .nav-link img {
  width: 3rem;
  height: 3rem;
}

.image-nav-tabs .nav-item .nav-link.active,
.image-nav-tabs .nav-item .nav-link:hover,
.image-nav-tabs .nav-item .nav-link:focus {
  border: 3px solid #271B75;
}

.notification-icon {
  position: relative;
}

.notification-icon .notification-icon__badge {
  /*background-color: #271B75;*/
  color: #fff;
  padding: 0 .3rem;
  font-weight: normal;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  border-radius: 10rem;
  /*position: absolute;*/
  /*top: .2rem;*/
  /*right: 0;*/
}

.page-detail-area .form-control {
  background-color: #fff;
}

.checkout-flow-steps-section {
  min-height: 15rem;
}

.order-summary-section {
  position: fixed;
  top: 8rem;
  left: 64%;
  right: 12%;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.order-summary-section.scroll {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
}

.zoom {
  display: inline-block;
  position: relative;
}

/* magnifying glass icon */
.zoom:after {
  content: '';
  display: block;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 0;
  right: 0;
  /*background: url(icon.png);*/
}

.zoom img {
  display: block;
}

.zoom img::selection {
  background-color: transparent;
}

body.dark-theme {
  background-color: #000;
  color: #fff;
}

body.dark-theme .footer {
  background-color: #000;
  border-top: 1px solid #212529;
}

body.dark-theme select, body.dark-theme input {
  background-color: #212529;
  color: #fff;
}

body.dark-theme .dropdown-menu {
  background-color: #000;
}

body.dark-theme .dropdown-menu .dropdown-item {
  color: #fff;
}

body.dark-theme .dropdown-menu .dropdown-item.active,
body.dark-theme .dropdown-menu .dropdown-item:hover {
  background-color: #212529;
}

body.dark-theme .white-bg {
  background-color: #000;
}

body.dark-theme .breadcrumb {
  background-color: #000;
  color: #fff;
}

body.dark-theme .form-control:disabled {
  background-color: #000;
}

body.dark-theme .card, body.dark-theme .form-control, body.dark-theme .input-group-text {
  background-color: #212529;
  color: #fff;
}

body.dark-theme .card .form-control, body.dark-theme .card .input-group-text {
  background-color: #343a40;
}

body.dark-theme .card .form-control:disabled {
  background-color: #000;
}

body.dark-theme .main-section .product-slider-section .carousel .card .products-section__image .products-section__offer-bottom {
  background-color: #000;
  color: #fff;
}

body.dark-theme .nav-tabs .nav-link.active {
  background-color: #212529;
  color: #fff;
}

body.dark-theme .products-section .card {
  background-color: #212529;
  color: #fff;
}

body.dark-theme .products-section .card .products-section__image .products-section__offer-top {
  background-color: #212529;
}

body.dark-theme .products-section .card .products-section__image .products-section__offer-bottom {
  background-color: #212529;
}

body.dark-theme .products-section .card .products-section__image .products-section__offer-bottom .products-section__product-name {
  color: #fff;
}

body.dark-theme .progress {
  background-color: #000;
}

body.dark-theme .pagination .page-item .page-link {
  background-color: #000;
  border-color: #212529;
  color: #fff;
}

@media (min-width: 1920px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1600px !important;
  }
  .main-banner-area-small {
    margin-top: 8.5rem;
  }
  .main-banner-area-small .carousel .carousel-inner .carousel-item .carousel-caption {
    top: 20% !important;
  }
  .main-banner-area-small .carousel .carousel-inner .carousel-item .carousel-caption .main-text {
    font-size: 4rem !important;
  }
}

@media (min-width: 1280px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1240px;
  }
}

.blur {
  position: absolute;
  color: white;
  backdrop-filter: blur(4px);
  bottom: 0;
  left: 0;
  right: 0;
  width: 500px;
  height: 50px;
}

@media (max-width: 769px) {
  .flex-shrink-0 {
    padding: 1rem 0 0 0 !important;
  }
  .large-font {
    font-size: 1rem;
  }
  .main-banner-area {
    margin-top: 7rem;
  }
  .main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption {
    left: 10%;
    right: 10%;
    top: 15%;
  }
  .main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption .main-text {
    font-size: 2rem;
  }
  .products-section .card .products-section__empty-card {
    min-height: 13.5rem;
    padding: 2.5rem 2rem;
  }
  .products-section .card .products-section__empty-card img {
    width: 100%;
  }
  .product-slider-section {
    padding: 2rem 2rem 1rem 2rem;
  }
  .product-slider-section .carousel .products-section .products-section__offer-bottom {
    bottom: -7rem;
  }
  .main-section .products-section.promote-products .card .products-section__offer-bottom {
    display: none;
  }
  .order-summary-section {
    top: 7.5rem;
    left: 58%;
    right: 5%;
  }
}

@media screen and (max-width: 576px) {
  .footer {
    padding-top: 2rem;
  }
  .main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption {
    left: 5%;
    right: 5%;
    top: 0;
  }
  .main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption .main-text {
    font-size: 1rem;
  }
  .main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption .carousel-caption__price-section {
    margin-top: 0;
    width: 45%;
  }
  .main-banner-area .carousel .carousel-indicators li {
    height: .5rem;
    width: .5rem;
  }
  .main-section {
    padding: 1rem 0;
  }
  .main-section .products-section.promote-products .card {
    overflow: hidden;
    /*height: 10rem;*/
    /*aspect-ratio: 1/1;*/
  }
  .main-section .products-section.promote-products .card .products-section__offer-bottom {
    bottom: 0;
    /*top: 4rem;*/
    padding: 1rem;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-bottom-left-radius: 0;
    -ms-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-bottom-right-radius: 0;
    -ms-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .main-section .product-slider-section {
    padding: 2rem 2rem 1rem 2rem;
  }
  .main-section .product-slider-section .carousel .carousel-control-prev {
    left: -2rem;
  }
  .main-section .product-slider-section .carousel .carousel-control-next {
    right: -2rem;
  }
  .products-section .card .products-section__empty-card {
    padding: 2.4rem 2rem;
  }
  .content-block__empty-row {
    min-height: 55vh;
  }
  .content-block__empty-row .content-block__empty-row-graphic {
    width: 12rem;
  }
  .order-summary-section {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
  }
  .order-summary-section.scroll {
    position: relative;
  }
}

header {
  background-color: #f8f9fa;
  position: relative;
  backdrop-filter: blur(5px);
}

header .navbar-brand {
  display: block;
  padding: 0;
  margin: 0;
}

header .navbar-brand img {
  height: 2.5rem;
}

header .navbar-brand__dark-theme {
  display: none;
}

header .dropdown .dropdown-menu .dropdown-item {
    /*padding: .8rem 1rem;*/
    padding: .7rem 0.4rem;
    margin: 0;
}

header .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

header .dropdown:hover .dropdown-menu.account-dropdown {
    display: grid;
    margin-top: 0;
}


header .dropdown:hover .dropdown-menu .dropdown-col-multi {
    min-width: 16.5rem;
    max-width: 16.5rem;
    padding-right: 0;


}

header .dropdown:hover .dropdown-menu .dropdown-col-single {
    min-width: 16.5rem;
    max-width: 16.5rem;
}

header .dropdown:hover .dropdown-menu .dropdown-col-single .dropdown-item {
    min-width: 16.5rem;
    max-width: 16.5rem;
}


header .dropdown:hover .dropdown-menu .dropdown-col-multi {
    min-width: 16.5rem;
    max-width: 16.5rem;
    padding-right: 0;


}

header .dropdown:hover .dropdown-menu .dropdown-col-single {
    min-width: 16.5rem;
    max-width: 16.5rem;
}

header .dropdown:hover .dropdown-menu .dropdown-col-single .dropdown-item {
    min-width: 16.5rem;
    max-width: 16.5rem;
}

header .btn {
    font-weight: normal;
}

header .mobile-menu-btn {
  position: absolute;
  /*top: .4rem;*/
  right: .6125rem;
  padding: 1rem;
  cursor: pointer;
  z-index: 1000;
  font-size: 15px;
  font-weight: 600;
  color: red;
}

header .mobile-menu-btn .mobile-menu-strip {
  width: 1.4rem;
  height: .1rem;
  background-color: black;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;

}

header .mobile-menu-open-btn {
  z-index: 1020;
}

header .mobile-menu-open-btn .mobile-menu-strip-1 {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  opacity: 1;
  top: .5rem;
}

header .mobile-menu-open-btn .mobile-menu-strip-2 {
  opacity: 1;
  top: 1rem;
}

header .mobile-menu-open-btn .mobile-menu-strip-3 {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  opacity: 1;
  top: 1.5rem;
}

header .mobile-menu-open-btn.inactive {
  z-index: 1010;
}

header .mobile-menu-open-btn.inactive .mobile-menu-strip-1 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
}

header .mobile-menu-open-btn.inactive .mobile-menu-strip-2 {
  opacity: 0;
}

header .mobile-menu-open-btn.inactive .mobile-menu-strip-3 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0;
}

header .mobile-menu-close-btn .mobile-menu-strip-1 {
  -webkit-transform: rotate(0);
  font-size: 5px;
  transform: rotate(0);
  opacity: 0;
  top: 1rem;
}

header .mobile-menu-close-btn .mobile-menu-strip-3 {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  font-size: 5px;
  opacity: 0;
  top: 1rem;
}

.custom-close-button-mobile{
  /*border: 1px solid red;*/
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 600;
  color: red;
  width: 40px;
  height: 40px;

}

header .mobile-menu-close-btn.active {
  z-index: 1020;
  width: 20px;
  height: 20px;
}

header .mobile-menu-close-btn.active .mobile-menu-strip-1 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 1;
}

header .mobile-menu-close-btn.active .mobile-menu-strip-3 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 1;
}

header .mobile-menu__dark-btn {
  position: absolute;
  top: .4rem;
  right: 7rem;
  padding: .6rem;
  cursor: pointer;
  z-index: 1000;
}

header .mobile-menu__cart-btn {
  position: relative;
  /*top: .4rem;*/
  /*right: 4rem;*/
  padding: .6rem;
  cursor: pointer;
  z-index: 1000;
}

header .header-second-menu {
  background-color: #fff;
}

header .header-nav-menu .row.menu-left{
    justify-content: space-between;
}

header .header-nav-menu .row.menu-center{
    justify-content: center;
}

header .header-nav-menu .row.menu-right{
    justify-content:flex-end;
}

header .header-nav-menu .btn {
    font-weight: bold;
    padding: .8rem .6rem;
    color: #6c757d;
}

header .header-second-menu .btn .fa-caret-down {
    color: #ced4da;
}

header .header-second-menu .btn:hover {
    color: #ffffff;
    background-color: var(--primary) ;
    border-radius: 0%;
}

header .header-second-menu .header-second-menu__right-dropdown-menu {
    left: auto !important;
    right: -8rem;
}

header .header-second-menu .dropdown .dropdown-menu {
    width: 100%;
}

header .header-second-menu .dropdown .dropdown-menu .dropdown-col{
    display: inline-block;
    width: 50%;
}


header .header-second-menu__search-input {
    background-color: #fff;
    /*margin-top: 1em;*/
    padding: 0;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    height: 0;
    overflow: hidden;
    opacity: 0;
}

header .header-second-menu__search-input .form-control:focus {
    box-shadow: none;
    background-color: #e9ecef;
}

header .header-second-menu__search-input.active {
    height: auto;
    overflow: auto;
    /*padding: 0 0 .5rem 0;*/
    opacity: 1;
}

header .header-second-menu__search-input.active > .container {
  margin-bottom: .5rem;
}

.header-with-promotion-banner {
  top: 2rem;
}

.header-second-menu__search-input-results {
  position: fixed;
  left: 0;
  right: 0;
  /*top: var(--search-bottom-margin, 9em);*/
  z-index: -1;
  opacity: 0;
  height: 0;
}

.header-second-menu__search-input-results .card {
  max-height: 22rem;
}

.header-second-menu__search-input-results.active {
  opacity: 1;
  height: auto;
  z-index: 1030;
}

.header-promotion-banner {
  padding: .2rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  background-color: #271B75;
  color: #fff;
  text-align: center;
  z-index: 1035;
}

.header-promotion-banner a {
  /*color: #fff;*/
}

.header-promotion-banner .header-promotion-banner__close {
  position: absolute;
  top: 0;
  right: 0;
}

.header-promotion-banner a {
  text-decoration: none;
  /*color: #fff;*/
}

body.dark-theme header {
  background-color: #000;
  color: #fff;
}

body.dark-theme header .navbar-brand__light-theme {
  display: none;
}

body.dark-theme header .navbar-brand__dark-theme {
  display: block;
}

body.dark-theme header .header-second-menu,
body.dark-theme header .header-second-menu__search-input {
  background-color: #000;
  color: #fff;
}

@media screen and (max-width: 992px) {
  header {
    background-color: #fff;
  }

  header .header-second-menu__search-input {
    height: auto;
    overflow: auto;
    padding: .5rem 0;
    opacity: 1;
  }
  header .mobile-menu-btn {
    right: 2rem;
  }
  header .mobile-menu__cart-btn {
    background-color: #271B75;
    border-radius: 4px;
    color: white;
    right: 5rem;
  }
  header .mobile-menu__dark-btn {
    right: 8rem;
  }
  .header-second-menu__search-input-results {
    top: 6rem;
    opacity: 1;
    height: auto;
    z-index: 1030;
  }
}
header .custom-mobile-login-button{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  padding: 20px 0 ;
  color: #271B75;
  background-color:white ;
  outline: none;
  border: none;
  text-align: center;
  border-bottom: 1px solid #d2d0d0;
  font-size: 15px;
  font-weight: 500;
}
.mobile-nav-heading{
  color: white;
  text-align: center;
  font-size: 20px;
  margin: 30px 0;
}

header .promo-nav-list{
  list-style-type: none;
  width: 100%;
  background: white;
  border-radius: 10px;
  /*background: black;*/
  padding: 0;
  margin: 0;
}
header .promo-nav-item {
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 10px;
  color: #271B75;
  padding: 20px 0;
  text-align:center;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #cccaca;
}

header .promo-nav-item a{
  color: #271B75;
}

@media screen and (max-width: 576px) {

  header .custom-mobile-login-button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;
    padding: 20px 0 ;
    color: #271B75;
    background-color:white ;
    outline: none;
    border: none;
    text-align: center;
    border-bottom: 1px solid #d2d0d0;
    font-size: 15px;
    font-weight: 500;
  }

  header .promo-nav-list{
    list-style-type: none;
    width: 100%;
    background: white;
    border-radius: 10px;
    /*background: black;*/
    padding: 0;
    margin: 0;
  }
  header .promo-nav-item {
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 10px;
    color: #271B75;
    padding: 20px 0;
    text-align:center;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #cccaca;
  }

  header .promo-nav-item a{
    color: #271B75;
  }

  header .custom-mobile-nav-dropdown{
    border-radius: 10px;
  }

  header .mobile-menu-btn {
    right: .6125rem;
  }
  .mobile-nav-heading{
    color: white;
    text-align: center;
    font-size: 20px;
    margin: 30px 0;
  }
  header .mobile-menu{
    background-color: #271B75;

  }
  header .mobile-menu__cart-btn {
    background-color: #271B75;
    border-radius: 4px;
    color: white;
    right: 4rem;
    padding: 5px 10px;
  }
  header .mobile-menu__dark-btn {
    right: auto;
    left: .6125rem;
  }
}

.notification-box {
  position: fixed;
  top: 0;
  z-index: 2060;
  width: 100%;
  height: 60px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  -vendor-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.notification-box.notify-failure {
  background-color: #f59e27;
  border-bottom: 1px solid #f59e27;
}

.notification-box.notify-success {
  background-color: #f59e27;
  border-bottom: 1px solid #f59e27;
}

.notification-box.notify-info {
  background-color: #f59e27;
  border-bottom: 1px solid #f59e27;
}

.notification-box__text {
  padding: 20px 0;
  float: left;
  color: #fff;
}

.notification-box__icon-area {
  padding: 8px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.notification-box__icon {
  font-size: 29px;
  color: #fff;
}

.notification-box__close i {
  cursor: pointer;
  font-size: 20px;
  margin-top: 20px;
  margin-right: 5px;
  float: right;
  color: #fff;
}

.notification-box__close .icon:hover {
  color: #fff;
  cursor: pointer;
}

.alert-info {
  background-color: #e5eeff;
}

body.dark-theme .alert-info {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .notification-box__icon-area {
    display: none;
  }
  .notification-box__close {
    position: absolute;
    top: 0;
    right: -10px;
  }
  .notification-box__text {
    padding: 20px 0;
  }
}

.modal-header .close {
  padding: .5rem .7rem;
  margin: -1rem -1rem -1rem auto;
  -webkit-border-radius: 0 3px 3px 3px;
  -moz-border-radius: 0 3px 3px 3px;
  -ms-border-radius: 0 3px 3px 3px;
  border-radius: 0 3px 3px 3px;
  font-weight: normal;
}

.modal-footer .btn {
  min-width: 5rem;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 420px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -420px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -420px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

body.dark-theme .modal-content {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

body.dark-theme .modal-content .modal-header {
  border-bottom-color: #212529;
}

body.dark-theme .modal-content .modal-body {
  background-color: #212529;
  color: #fff;
}

body.dark-theme .modal-content .modal-footer {
  border-top-color: #212529;
}

body.dark-theme .modal-content .form-control {
  background-color: #343a40;
}

.table-actions {
  text-align: right;
}

.table-actions .btn {
  padding: 7px 12px;
  color: #212529;
}

.table-actions .btn i {
  margin-right: 0;
}

.table-actions .btn:hover {
  background-color: #f8f9fa;
  color: #f59e27;
}

.table-responsive {
  padding: 15px;
}

.table-responsive .table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;
}

.table-responsive .table thead tr th {
  background-color: #f8f9fa;
}

.table-responsive .table thead tr th:first-child {
  -webkit-border-top-left-radius: 0.25rem;
  -moz-border-top-left-radius: 0.25rem;
  -ms-border-top-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.table-responsive .table thead tr th:last-child {
  -webkit-border-top-right-radius: 0.25rem;
  -moz-border-top-right-radius: 0.25rem;
  -ms-border-top-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.table-responsive .table tbody tr {
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.table-responsive .table tbody tr .responsive-mobile-heading {
  display: none;
  width: 40%;
}

.table-responsive .table tbody tr td:first-child {
  -webkit-border-top-left-radius: 0.25rem;
  -moz-border-top-left-radius: 0.25rem;
  -ms-border-top-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  -webkit-border-bottom-left-radius: 0.25rem;
  -moz-border-bottom-left-radius: 0.25rem;
  -ms-border-bottom-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.table-responsive .table tbody tr td:last-child {
  -webkit-border-top-right-radius: 0.25rem;
  -moz-border-top-right-radius: 0.25rem;
  -ms-border-top-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  -webkit-border-bottom-right-radius: 0.25rem;
  -moz-border-bottom-right-radius: 0.25rem;
  -ms-border-bottom-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.table-responsive .table tbody tr:hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

table th, table td {
  vertical-align: middle !important;
}

body.dark-theme .table {
  background-color: #212529;
  color: #fff;
}

body.dark-theme .table-responsive {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

body.dark-theme .table-responsive .table {
  background-color: #212529;
  color: #fff;
}

body.dark-theme .table-responsive .table thead tr {
  background-color: #212529;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

body.dark-theme .table-responsive .table thead tr th, body.dark-theme .table-responsive .table thead tr td {
  background-color: #212529;
}

body.dark-theme .table-responsive .table tbody tr {
  background-color: #212529;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

body.dark-theme .table-responsive .table tbody tr th, body.dark-theme .table-responsive .table tbody tr td {
  background-color: #212529;
}

body.dark-theme .table-responsive .table tbody tr:hover {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

body.dark-theme .table-actions .btn {
  color: #fff;
}

body.dark-theme .table-actions .btn:hover {
  background-color: #343a40;
  color: #271B75;
}

@media only screen and (max-width: 769px) {
  /* Force table to not be like tables anymore */
  .table-responsive table, .table-responsive thead, .table-responsive tbody, .table-responsive th, .table-responsive td, .table-responsive tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .table-responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .table-responsive td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
  }
  .table-responsive td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  .table-actions {
    text-align: left;
  }
  .table-responsive .table tbody tr {
    box-shadow: none;
    border-bottom: 1px solid #e9ecef;
  }
  .table-responsive .table tbody tr .responsive-mobile-heading {
    display: inline-block;
    width: 40%;
    color: #212529;
    font-weight: bold;
  }
}

.sidebar {
  z-index: 1030;
  padding: 1rem;
  /*background-color: #E2EFD1;*/
  background-color: rgba(39,27,117,0.05);
  overflow-y: auto;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 7px;
}

.sidebar .sidebar-list {
  margin: 0 0 20px 0;
  padding: 0;
}

.sidebar .sidebar-list .sidebar-list__item {
  list-style: none;
  position: relative;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link {
  display: block;
  color: #6c757d;
  padding: .3rem 1rem;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link .sidebar-list__item-link--icon {
  margin: 0.3rem 0;
  display: block;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link .sidebar-list__item-link--text {
  display: block;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link .sidebar-list__item-link--badge {
  position: absolute;
  top: .6rem;
  right: .6rem;
  padding: .1rem .4rem;
  font-size: 11px;
  background-color: #271B75;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  border-radius: 10rem;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link:hover {
  text-decoration: none;
  color: #271B75;
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link .sidebar-list__item-link-icon--open,
.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link .sidebar-list__item-link-icon--close {
  float: right;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link .sidebar-list__item-link-icon--open i,
.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link .sidebar-list__item-link-icon--close i {
  color: #212529 !important;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link .sidebar-list__item-link-icon--open {
  display: none;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link .sidebar-list__item-link-icon--close {
  display: block;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link.collapsed .sidebar-list__item-link-icon--open {
  display: block;
}

.sidebar .sidebar-list .sidebar-list__item .sidebar-list__item-link.collapsed .sidebar-list__item-link-icon--close {
  display: none;
}

.sidebar .sidebar-list .sidebar-list__item.active .sidebar-list__item-link {
  color: #271B75;
}

.sidebar .sidebar-list .sidebar-list__item.active .sidebar-list__item-link i {
  color: #271B75;
}

.product-sidebar-list{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 575px) {
  .product-sidebar-list{
    display: none;
  }
}

.product-view-brands {
  height: auto; /* Default height */
}

.sidebar .form-control {
  min-height: 2.5rem;
}

.mobile-menu {
  position: fixed;
  left: 100%;
  right: -100%;
  height: 100vh;
  padding: 1rem;
  z-index: 1050;
  background-color: #271B75;
  overflow: auto;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: .2em;

}

.mobile-menu .mobile-menu__left-btn {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.mobile-menu .mobile-menu-list {
  margin-top: .5rem;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item {
  position: relative;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding-left: 3rem;
}

/*.mobile-menu .mobile-menu-list .mobile-menu-list__item .mobile-menu-list__item-link {*/
/*  padding: .5rem 0;*/
/*  color: #6c757d;*/
/*  display: block;*/
/*  font-weight: bold;*/
/*  -moz-transition: all 0.3s ease 0s;*/
/*  -o-transition: all 0.3s ease 0s;*/
/*  -webkit-transition: all 0.3s ease 0s;*/
/*  transition: all 0.3s ease 0s;*/
/*}*/
.accordion:not(:last-child) {
  border-bottom: 1px solid #d5d4d4;
}

.mobile-menu-list__item .mobile-menu-list__item-link {
  text-align: center;
  color: #271B75;
  display: block;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: .2em;
  font-weight: 500;
  padding: 20px 0;
  font-size: 16px;
  /*margin-bottom: 20px;*/
}


/*.mobile-menu .mobile-menu-list .mobile-menu-list__item .mobile-menu-list__item-link:hover,*/
/*.mobile-menu .mobile-menu-list .mobile-menu-list__item .mobile-menu-list__item-link.active {*/
/*  color: #271B75;*/
/*  text-decoration: none;*/
/*}*/

.mobile-menu .mobile-menu-list .mobile-menu-list__item .mobile-menu-list__item-link:hover,
.mobile-menu .mobile-menu-list .mobile-menu-list__item .mobile-menu-list__item-link.active {
  color: #ffffff;
  background-color: var(--primary);
  text-decoration: none;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .mobile-menu-list-sub-list .mobile-menu-list-sub-list__item .mobile-menu-list-sub-list__item-link {
  padding: .5rem 1rem .5rem 2rem;
  color: #6c757d;
  display: block;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .mobile-menu-list-sub-list .mobile-menu-list-sub-list__item .mobile-menu-list-sub-list__item-link i {
  width: 1rem;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .mobile-menu-list-sub-list .mobile-menu-list-sub-list__item .mobile-menu-list-sub-list__item-link:hover,
.mobile-menu .mobile-menu-list .mobile-menu-list__item .mobile-menu-list-sub-list .mobile-menu-list-sub-list__item .mobile-menu-list-sub-list__item-link.active {
  color: #271B75;
  text-decoration: none;
  font-weight: bold;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .accordion .btn-link {
  padding: .5rem 0;
  color: #6c757d;
  font-weight: bold;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .accordion .btn-link:hover,
.mobile-menu .mobile-menu-list .mobile-menu-list__item .accordion .btn-link:focus {
  text-decoration: none;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .accordion .mobile-menu-list__item-link {
  font-weight: 500;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .accordion .collapse-menu__open {
  display: none;
  color: #e9ecef;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .accordion .collapse-menu__close {
  display: block;
  color: #e9ecef;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .accordion .collapsed .collapse-menu__open {
  display: block;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item .accordion .collapsed .collapse-menu__close {
  display: none;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(1) {
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(2) {
  -moz-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(3) {
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(4) {
  -moz-transition-delay: 0.25s;
  -o-transition-delay: 0.25s;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(5) {
  -moz-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(6) {
  -moz-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(7) {
  -moz-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(8) {
  -moz-transition-delay: 0.45s;
  -o-transition-delay: 0.45s;
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(9) {
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(10) {
  -moz-transition-delay: 0.55s;
  -o-transition-delay: 0.55s;
  -webkit-transition-delay: 0.55s;
  transition-delay: 0.55s;
}

.mobile-menu .mobile-menu-list .mobile-menu-list__item:nth-child(11) {
  -moz-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.mobile-menu.active {
  left: 0;
  right: 0;
}

.mobile-menu.active .mobile-menu-list .mobile-menu-list__item {
  padding-left: 0;
}

body.dark-theme .mobile-menu {
  background-color: #000;
  color: #fff;
}

body.dark-theme .mobile-menu .card {
  background-color: #000;
  color: #fff;
}

body.dark-theme .sidebar {
  background-color: #000;
  color: #fff;
}

@media screen and (max-width: 769px) {
  .sidebar {
    width: auto;
    right: 0;
  }
  .sidebar .sidebar-header {
    width: auto;
    right: 0;
  }
  .sidebar .sidebar-header .sidebar-logo {
    margin-left: 2.5rem;
  }
  .sidebar .sidebar-header .sidebar-logo .sidebar-header__text {
    left: 10rem;
    font-size: .8rem;
    top: 1.5rem;
  }
  .sidebar .sidebar-header .sidebar__close-web-icon {
    display: none;
    padding: .5rem 1rem;
  }
  .sidebar .sidebar-header .sidebar__close-mobile-icon {
    display: block;
  }
  .sidebar .sidebar__tab-area .sidebar-list .sidebar-list__item .sidebar-list__item-link {
    padding: .5rem .3rem;
  }
  .sidebar .sidebar__user-account-list {
    top: 65%;
  }
  .sidebar.closed {
    bottom: auto;
  }
  .sidebar.closed .sidebar-header {
    padding-bottom: 1rem;
  }
  .sidebar.closed .sidebar-header .sidebar__open-mobile-icon {
    display: block;
  }
  .sidebar.closed .sidebar-header .sidebar__close-mobile-icon {
    display: none;
  }
  .sidebar.sidebar-small .sidebar-header .sidebar-logo {
    margin-left: 0;
  }
  .sidebar.sidebar-small .sidebar-header .sidebar-logo .sidebar-header__text {
    font-size: .5rem;
  }
}

.top-menu {
  position: fixed;
  left: 0;
  right: 0;
  padding: .5rem;
  z-index: 1050;
  background-color: #fff;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-shadow: 0 0 20px 0 rgba(154, 161, 171, 0.15);
  -moz-box-shadow: 0 0 20px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 20px 0 rgba(154, 161, 171, 0.15);
}

.top-menu .input-group .form-control {
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  padding-left: 0;
}

.top-menu .input-group .form-control:focus {
  box-shadow: none;
}

.top-menu .input-group .input-group-append .btn {
  border-bottom: 1px solid #dee2e6;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding: 0 .75rem;
}

.top-menu .top-menu__logo img {
  width: 5rem;
}

.top-menu .top-menu__logo .top-menu__logo-text {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 80%;
}

.top-menu .top-menu__open-mobile-menu {
  position: fixed;
  left: .5rem;
  top: 1rem;
  color: #adb5bd;
  padding: .5rem;
  cursor: pointer;
  z-index: 1050;
}

.top-menu .top-menu__close-mobile-menu {
  position: fixed;
  left: .5rem;
  top: 1rem;
  color: #adb5bd;
  padding: .5rem;
  cursor: pointer;
  z-index: 1050;
  display: none;
}

.top-menu .top-menu__right-action {
  padding: 0;
  margin: 0;
  display: flex;
}

.top-menu .top-menu__right-action .top-menu__right-action-item {
  list-style: none;
  display: flex;
  margin-left: .8rem;
}

.top-menu .top-menu__right-action .top-menu__right-action-item .top-menu__right-action-item-link {
  display: block;
  padding: 1rem;
  width: 3rem;
  height: 3rem;
  background-color: #fff;
  color: #212529;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  border-radius: 10rem;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.top-menu .top-menu__right-action .top-menu__right-action-item .top-menu__right-action-item-link .top-menu__right-action-item-link-badge {
  position: absolute;
  top: 0;
  right: -.5rem;
  padding: .1rem .4rem;
  font-size: 11px;
  background-color: #f59e27;
  color: #fff;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  border-radius: 10rem;
}

.top-menu .top-menu__right-action .top-menu__right-action-item .top-menu__right-action-item-link:hover {
  color: #f59e27;
  -webkit-box-shadow: 0 0.5rem 1rem #dee2e6;
  -moz-box-shadow: 0 0.5rem 1rem #dee2e6;
  box-shadow: 0 0.5rem 1rem #dee2e6;
}

.top-menu .top-menu__right-action .top-menu__right-action-item .dropdown-menu {
  min-width: 12rem;
  padding: 0;
  margin: 0;
}

.top-menu .top-menu__right-action .top-menu__right-action-item .dropdown-menu .dropdown-item {
  padding: 0.8rem .8rem;
}

.top-menu .top-menu__right-action .top-menu__right-action-item-user .top-menu__right-action-item-link {
  width: auto;
  padding: .4rem .3rem .4rem 1rem;
}

.top-menu .top-menu__right-action .top-menu__right-action-item-user .top-menu__right-action-item-link .top-menu__right-action-item-user-text {
  display: inline-block;
}

.top-menu .top-menu__right-action .top-menu__right-action-item-user .top-menu__right-action-item-link img {
  width: 2.3rem;
  height: 2.3rem;
  margin-left: .5rem;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  border-radius: 10rem;
}

.top-menu .top-menu__main-search-area {
  height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.top-menu .top-menu__main-search-area .top-menu__main-search-area--close {
  position: fixed;
  right: 5rem;
  top: 5rem;
}

.top-menu .top-menu__main-search-area .top-menu__main-search-area--close .btn {
  color: #dee2e6;
  border: 1px solid #dee2e6;
}

.top-menu .top-menu__main-search-area .top-menu__main-search-area--close .btn:hover {
  color: #adb5bd;
  border: 1px solid #adb5bd;
}

.top-menu.minimized {
  left: 0;
}

body.dark-theme .top-menu {
  background-color: #000;
  border-color: #212529;
}

body.dark-theme .top-menu .input-group .form-control {
  border: 1px solid #212529;
  padding-left: 1rem;
  background-color: #212529;
}

body.dark-theme .top-menu .top-menu__right-action .top-menu__right-action-item .top-menu__right-action-item-link {
  background-color: #343a40;
  color: #fff;
}

body.dark-theme .top-menu .top-menu__right-action .top-menu__right-action-item .top-menu__right-action-item-link:hover {
  color: #f59e27;
  -webkit-box-shadow: 0 0.5rem 1rem #212529;
  -moz-box-shadow: 0 0.5rem 1rem #212529;
  box-shadow: 0 0.5rem 1rem #212529;
}

@media screen and (max-width: 480px) {
  .top-menu {
    border-bottom: 1px solid #dee2e6;
  }
  .top-menu .top-menu__logo {
    text-align: center;
  }
  .top-menu .top-menu__close-mobile-menu {
    display: block;
  }
}

.right-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30%;
  z-index: 1050;
  background-color: #fff;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.right-panel .right-panel__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: .8rem 1rem;
  color: #6c757d;
  cursor: pointer;
}

.right-panel__closed {
  right: -40%;
}

@media (max-width: 769px) {
  .right-panel {
    width: 50%;
  }
  .right-panel__closed {
    right: -60%;
  }
}

@media (max-width: 480px) {
  .right-panel {
    width: 90%;
  }
  .right-panel__closed {
    right: -100%;
  }
}

.search-active .flex-shrink-0 {
  padding: 10rem 0 0 0;
}

.cursor-pointer {
  cursor: pointer;
}

.alias-pointer {
  cursor: alias;
}

.special-price-strike {
  color: gray;
  position: relative;
  bottom: 0;
  align-items: end;
  font-size: 12px;
  text-decoration: line-through ;
}

.special-price-perc {
  position: relative;
  bottom: 0;
  align-items: end;
  font-size: 12px;
  text-align: start;
  margin-left: 5px;
  color: red;
}

.font-enlarged {
  font-size: 14px;
}

.font-pricing{
  color : #495057
}

.disabledStyle {
  border-color:  #666;
  background-color: #666;
}

.enabledStyle {
  background-color: #ffffff !important;
}

.cartList{
  height:auto;
  overflow-y:auto;
  max-height: calc(100vh - 250px);
  overflow-x: hidden;
  margin-right: 10px;
  width: auto;
  padding-top: 10px;
}
.cart-display-container{
  background-color: #e5e4e4;
  border-radius: 10px;
  /*position: relative;*/
}
.clear-product-btn{
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/*Slider Image Css*/

@media (max-width: 576px) {
  .slide-image {
    height: 30vh !important;

  }
}

@media (min-width: 768px) {
  .slide-image {
    height: 40vh !important;

  }
}

@media (min-width: 992px) {
  .slide-image {
    height: 75vh !important;

  }
}

@media (min-width:  1024px) {
  .slide-image {
    height: 50vh !important;
  }
}

@media (min-width: 1200px) {
  .slide-image {
    height: 75vh !important;

  }
}

.slide-image {
  height: calc(100vh - 415px);

}

.main-banner-area .carousel .carousel-inner .carousel-item .carousel-caption{
  left: 10% !important;
  right: 40% !important;
  top: 33% !important;
  text-align: left !important;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn.btn-gray{
  color: #000000;
  background-color: #dbd3d3;
}

.btn-group-toggle .btn-gray.active {
  background-color: #271B75 !important;
  border-color: #271B75 !important;
  color: #fff !important;
}


/* Main banner configurations */
.container .main-banner-button {
  text-transform: uppercase;
  background-color: #6c757d;
  /*border: 2px solid #000;*/
  border-radius: 0.6rem;
}

.main-banner-text-size {
  font-size: 4rem;
}

.container .main-banner_price-section {
  margin-top: 1rem;
  width: 20%;
}

@media (min-width: 1920px) {
  .main-banner-text-size {
    font-size: 4rem;
  }

  .main-banner-description_size {
    font-size: 24px !important;
  }
}

@media (max-width: 1024px) {

  .main-banner-text-size {
    font-size: 1.5rem;
  }

  .main-banner-description_size {
    font-size: 14px !important;
  }

  .center-banner-bottom-alignment {
    bottom: 20% !important;
  }
}

@media (min-width: 1023px) {

  .main-banner-description_size {
    font-size: 16px !important;
  }
}

@media (max-width: 820px) {

  .main-banner-text-size {
    font-size: 1.5rem;
  }

  .main-banner-description_size {
    font-size: 14px !important;
  }

  .center-banner-bottom-alignment {
    bottom: 20% !important;
  }
}

@media (max-width: 769px) {
  .main-banner-text-size {
    font-size: 2rem;
  }

  .main-banner__btn-font-size {
    font-size: 12px !important;
  }

  .main-banner-description_size {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 576px) {
  .main-banner-text-size {
    font-size: 1rem !important;
  }

  .main-banner__btn-font-size {
    font-size: 12px !important;
  }

  .container .main-banner_price-section {
    margin-top: 0.5rem;
    width: 40%;
  }

  .main-banner-description_size {
    font-size: 10px !important;
  }
}

.scrollbar-hidden::-webkit-scrollbar {
  display:none;
  -webkit-overflow-scrolling: touch;
}

.scrollbar-hidden{
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Toastify > div {
  top: 0;
  margin-top: 0;
  padding: 0;
  width: 100vw;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem #271B75!important;
}

.form-group > label.text-danger {
  color: #212529!important;
}

.form-control.is-invalid {
  margin-bottom: .4rem;
  border-style: solid;
  border-radius: 5px;
  border-color: red;
  border-width: medium;
}

.btn-icon.order-failure-btn-icon {
  color: inherit;
  height: 40px;
  width: 40px;
}

@media (max-width: 768px) {
  .order-failure-btn-icon {
    height: 35px !important;
    width: 35px !important;
  }
}

.btn-text__icon.order-failure-btn-icon {
  color: inherit;
}

.btn-icon.btn-icon-footer {
  color: inherit;
  height: 40px;
  width: 40px;
}

@media (max-width: 768px) {
  .btn-icon-footer {
    height: 25px !important;
    width: 25px !important;
  }
}

.btn-text__icon.btn-icon-footer {
  color: inherit;
}

.btn.btn-group.open-hours-button {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0;
  color: #271B75;
  border: none;
}

.btn.btn-group.open-hours-button > .arrow-down {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
}

.btn.btn-group.open-hours-button > .arrow-down.open {
  transform: rotate(180deg);
  transition: transform 0.2s linear;
}

/*span.page-container > ul > li > span {*/
/*  background-color: transparent !important;*/
/*}*/

/*span.page-container > h1 > *, h2 > *, h3 > *, h4 > *, h5 > *, h6 > *, ul > *, ol > *, span > *, img > *{*/
/*  background-color: transparent !important;*/
/*}*/

@media (max-width: 768px) and (max-width: 1024px){
  .page-content img {
    width: 380px !important;
  }
}

@media (max-width: 400px) and (max-width: 700px) {
  .page-content img {
    width: 300px !important;
    margin: 0 18px 10px;
  }
}

@media (max-width: 350px){
  .page-content img {
    width: 250px !important;
  }
}

@media (max-width: 280px){
  .page-content img {
    width: 200px !important;
  }
}

/*  */

/* dropdown */
/*.header-nav-menu{*/
/*  margin-left: 180px;*/
/*}*/
.header-nav-menu ul li .btn.nav-item:hover{
  padding-bottom: .5rem;
  border-bottom: 5px solid var(--primary);
  transition: all .1s;
}

.header-nav-menu .dropdown{
  position: static;
  transition: all 0.2s;
}


.header-nav-menu .dropdown:hover .btn{
  padding-bottom: 8px;
  border-bottom: 5px solid var(--primary);
  transition: all .1s;
}

.header-nav-menu .dropdown .dropdown-menu{
  position: absolute;
  background-color: var(--dark);
  color: #fff;
  display: none;
  left: 0;
  right: 0;
  width: 100vw;
  transition: all 0.2s;
}

.header-nav-menu .dropdown:hover .dropdown-menu,
.header-nav-menu .dropdown .dropdown-menu:hover{
  display: block !important;
  width: 100%;
  transition: all 0.2s;
}

.menu-content{
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.dropdown-a-list,
.dropdown-ad-list{
  margin-top: 2rem;
}

.dropdown-a-list ul{
  padding-left: 0;
  list-style: none;
  line-height: 1rem;
}
.dropdown-a-list ul li{
  min-width: 80%;
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 1px 0;
  transition: all .2s;
}

/* checkout pagination */

/*.main-section .checkout__header.active .checkout__header-icon {*/
/*  color: #271B75;*/
/*}*/

/*.main-section .checkout__card-icon.active {*/
/*  color: #271B75;*/
/*}*/

/*.main-section .checkout__header.inactive .checkout__header-icon {*/
/*  color: #adb5bd;*/
/*}*/

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
  justify-content: space-between;
  align-items: center;
}

.stepwizard-3 {
  display: table;
  width: 29%;
  position: relative;

}

.stepwizard-names-3 {
  display: table;
  width: 41%;
  position: static;

}

.stepwizard-2 {
  display: table;
  width: 16%;
  position: relative;

}

.stepwizard-names-2 {
  display: table;
  width: 25%;
  position: static;

}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  /* filter: alpha(opacity=100) !important; */
  background-color: rgb(233, 233, 233);
  border-style:solid ;
}

.stepwizard-row:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 2px;
  background-color: #ccc;

}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
  align-items: center;
  padding: 0;

}

.start-step{
  text-align: start;
}

.center-step{
  padding: 0 160px 0 160px;
}

.end-step{
  text-align: right;
}

.btn-circle {
  width: 2rem;
  height: 2rem;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 30px;
}

.btn.is-active{
  background-color: #271B75;
  color: #fff;
  width: 3em;
  height: 3em;
  font-size: 14px;
}

.btn.completed{
  background-color: #e2e2e2;
  color: rgb(34, 34, 34);
  /*box-shadow: #271B75;*/
}

/* map */

.map-container{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.map-container iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}

/* radio card */

.card-input-element+.card {
  -webkit-box-shadow: none;
  box-shadow: 0 0 0 1pt #e0e3e6;
  border: 2px solid transparent;
  border-radius: 6px;

}

.card-input-element+.card:hover {
  cursor: pointer;
}

.card-input-element:checked+.card {
  border: 2px solid var(--primary);
  -webkit-transition: border .3s;
  -o-transition: border .3s;
  transition: border .3s;
  box-shadow: none;
}

.address-book{
  display:flex;
}

.content-boxes{
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .center-step{
    padding: 0 80px 0 80px;
  }

  .stepwizard-names-3 {
    display: table;
    width: 45%;
    position: static;
  }


}


@media screen and (max-width: 769px) {

  .stepwizard-3 {
    display: table;
    width: 70%;
    position: relative;

  }


  .stepwizard-2 {
    display: table;
    width: 50%;
    position: relative;

  }

  .stepwizard-names-2 {
    display: table;
    width: 80%;
    position: static;

  }

  /*.center-step{*/
  /*  !*text-align: left;*!*/
  /*  padding-right: 175px;*/
  /*}*/


  .address-book{
    display:list-item;
  }

  .stepwizard-names-3 {
    display: table;
    width: 150%;
    position: static;

  }
  .mobile-menu-section{
    background-color: white;
    margin-bottom: .5em;
    /*padding: .8em;*/
    border-radius: 10px;

  }
}


.mobile-menu-section{
  background-color: white;
  margin-bottom: .5em;
  /*padding: .8em;*/
  border-radius: 10px;
}

/*@media (max-width: 576px) {*/
/*  .center-step{*/
/*    padding-right: 70px;*/
/*  }*/
/*}*/
.dropdown-a-list a,
.dropdown-ad-list a {
  text-decoration: none;
  color: #fff;
  padding: 2px 4rem 2px 0px;


}
.dropdown-ad-list a .card {
  transition: all .2s;
}

/*  */

.dropdown-ad-list a:hover .card{
  transform: scale(1.05);
  transition: all .2s;
}

.dropdown-a-list li:hover a{
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  padding: 0px 3.5rem 0px .5rem;
  transition: all .2s;
}

.dropdown-a-list li:hover{
  padding: 0;
}

.ad-section__product-name {
  width: 100%;
  padding-top: .2rem;
  padding-bottom: 0;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  display: block;
}

/* header mobile */

.mobile-menu-list__item-link--text{
  margin: auto;
}

.mobile-menu-list-label{
  position: relative;
  padding: 0 .8rem;
  list-style: none;
}



body.dark-theme .mobile-menu-section{
  background-color: rgba(17, 17, 17, 0.973);
  margin-bottom: .5em;

}

body.dark-theme .sidebar-label{
  font-weight: bold;
  color:#dfdfdfbd;
  font-size: .8rem;

}

.sidebar-label{
  font-weight: bold;
  color:#3a3a3abd;
  font-size: .8rem;

}

/* toggle dropdown*/

.btn-toggle {
  display: inline-flex;

}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  /*content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");*/
  transition: transform .35s ease;
  transform-origin: .5em 50%;
  transform: rotate(90deg);
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.dropdown-menu .cart-popup {
  max-width: 80em !important;
}

.dropdown-menu .dropdown-item .cart-popup {
  padding: .7rem 0.4rem;
}

.dropdown-menu .top-bar.login-dropdown {
  font-size: .75rem;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
.custom-login-button{
  border: 1px solid #271B75;
  color: #271B75;
}

.custom-login-button:hover {
  border: 1px solid #271B75;
  background-color: white;

}
.topbar-login-dropdown  {
  position: absolute;
  top: 100%;
  left: -10px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.topbar-login-dropdown {
  font-size: .75rem;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.topbar-login-dropdown .dropdown-item {
  padding: 0.25rem .8rem;
  width: 100%;
  font-size: .75rem;
}

.topbar-login-dropdown .dropdown-item:hover,
.topbar-login-dropdown .dropdown-item.active {
  color: #271B75;
}

.topbar-login-dropdown .dropdown-item:active,
.topbar-login-dropdown .dropdown-item.active {
  background-color: transparent;
}

.dropdown-menu-more{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-more {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-more {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-more {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-more {
    max-width: 1140px;
  }
}


.menu-content .nav.tab-menu .nav-link {
  color: white;
  width: 15rem;
  margin-bottom: .6rem;
}

.menu-content .nav.tab-menu .nav-link.active {
  background-color: var(--secondary);
}

.more-menu-items {
  overflow: auto;
  max-height: 50vh;
}

.more-menus {
  overflow: auto;
  max-height: 50vh;
}

.more-menu-items::-webkit-scrollbar {
  width: 5px;
}

.more-menu-items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.more-menu-items::-webkit-scrollbar-thumb {
  background: #f59e27;
  border-radius: 10px;
}

.more-menu-items::-webkit-scrollbar-thumb:hover {
  background: #f59e27!important;
}

.border-on-error {
  border: solid red !important;
  border-radius: 5px;
  margin-bottom: 0.4rem !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)
}

.color {
  position: relative;
  text-transform: uppercase;
}
.color__box-1 {
  grid-column: 2/3;
  grid-row: 3/4;
}
.color__box-2 {
  grid-column: 3/4;
  grid-row: 3/4;
}
@media screen and (max-width: 573px) {
  .color__box-2 {
    grid-column: 2/3;
    grid-row: 4/5;
  }
  .color__box-2.move {
    transition: all 0.4s linear;
    transform: translateY(300%);
  }
}


.color__primary, .color__secondary {
  display: flex;
  justify-content: space-between;
  top: 0px;
  left: 0px;
  width: 240px;
  padding: 20px 20px;
  font-size: 18px;
  border-radius: 8px;
  color: #fff;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
}
.color__btn-1, .color__btn-2 {
  position: absolute;
  width: 240px;
  display: flex;
  justify-content: center;
  background-color: #e0e0e0;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  color: grey;
}
.color__btn-1, .color__btn-1 > *, .color__btn-2, .color__btn-2 > * {
  transition: all 0.4s linear;
}
.color__btn-1.move, .color__btn-2.move {
  transform: translateY(200px);
  margin-bottom: 50px;
}
.color__btn-1.move > *, .color__btn-2.move > * {
  transform: rotate(-180deg);
}

.color-1,
.color-5 {
  z-index: 400;
  position: relative;
}

.color-1 {
  background-color: #7234f0;
}

.copy {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  opacity: 0.6;
  transition: all 0.1s ease;
}
.copy:hover {
  transform: translateY(-4px);
  opacity: 1;
}

.tooltip {
  position: absolute;
  top: 30px;
  left: -30px;
  background: #373737;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  letter-spacing: 1px;
  opacity: 0;
}
.tooltip.appear {
  animation: appear 1s ease;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  20% {
    transform: translateY(10px);
    opacity: 1;
  }
  80% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.newsletter-subscribe {
  position: relative;
}

.newsletter-subscribe-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 10px 100px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
}
.newsletter-subscribe-button {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  background: #d1095e;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 10px;
  border-radius: 100px;
  z-index: 2;
  font-size: 0.8rem;
}
/*view all button */
.view-all-button{
  height: 40px;
  width: 150px;
  border: 2px solid #333333;
  border-radius: 8px;
  background-color: white
}

.view-all-button-text{
  font-size: 1rem;
  color: #272727;
}

.view-all-button-text:hover {
  text-decoration: none;
}

.view-all-icon{
  margin-left: 5px;
}
/*feature banner */
.banner-text-area{
  filter: opacity(80%);
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 0px 0px 8px 8px;
}

.banner-text{
    text-align: center;
    font-size: 26px;
    font-weight: 900;
    line-height: 32px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.img-border {
  width: 100%;
  max-height: 100px;
  max-width: 100px;
  border: 1px solid #D5D5D5;
  border-radius: 4px;
}

/* Social Icon Styles */
.social-facebook {
  color: white !important;
  background-color: #3C599F !important;
}
.social-instagram {
  display: inline-block;
  text-align: center;
  color: white !important;
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}
.social-whatsApp {
  color: white !important;
  background-color: #5FC356 !important;
}
.social-youtube {
  color: white !important;
  background-color: #C52F30 !important;
  /*padding-left: 0.85em !important;*/
}
.btn-icon .fa-youtube {
  width: 1em !important;
}
.social-linkedIn {
  color: white !important;
  background-color: #1472B1 !important;
}
.social-twitter {
  color: white !important;
  background-color: #32CCFE !important;
}
.social-tikTok {
  color: white !important;
  background-color: black !important;
}
.collapsible {
  background-color: white;
  font-size: 0.75rem!important;
  min-height: 3rem;
  color: #495057 !important;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border-radius: 0.25rem;
  text-align: left;
  outline: none;
  border: 1px solid #ccc;
}

/* .active, .collapsible:hover {
  background-color: #555;
} */

.content {
  /* padding: 0 18px; */
  display: none;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: white;
}

/* Style the buttons inside the tab */
.address-section-tabs {
  background-color: white;
  float: left;
  outline: none !important;
  cursor: pointer;
  padding: 14px 13px;
  transition: 0.3s;
  font-size: 0.75rem!important;
  min-height: 3rem;
  border-width: 0px;
}

.address-section-tabs:hover {
  outline: 0 !important
}


/*.address-section-main-btn {*/
/*  border-color: #271B75 !important;*/
/*  box-shadow: 0 0 0 0.05rem #271B75 !important;*/
/*  outline: none !important;*/
/*}*/

.address-section-main-btn:focus {
  border-color: #271B75 !important;
  box-shadow: 0 0 0 0.05rem #271B75 !important;
  outline: none !important;
}
/* Change background color of buttons on hover */
.content button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.content button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  border: 1px solid #ccc;
  /* border-top: none; */
  background-color: white;
}

.chevron-down{
  float: right;
}

.active-tab {
  border-bottom-width: 1px !important;
  color: red !important;
  border-bottom-color: red !important;
}

.modal-backdrop {
  z-index: -1;
}

.modal-container {
  padding-left: 15px;
  padding-right: 15px;
  top: 80px;

}

.orderLabel {
  text-align: center;
  padding: 30px 0;
  color: #271B75;
  font-weight: bold;

}

.react_times_button {
  background-color: #dbd3d3 !important;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-radius: 0.25rem !important;
}

.time_picker_container .classic_theme_container {
  bottom: 80px !important;
  top: unset !important;
}

.page-link-container:hover {
  opacity: 0.7;
}


/* carousel */

.carousel-topic{
  text-decoration: none;
  float: right;
  color: #272727;
}
.carousel-topic :hover{
  color: #141414;
}
.outer-carousel-container{
  background-color: #e1e1e1;
}
.carousel {
  /* margin: 30px auto 60px; */
  /* padding: 0 80px; */
}
.carousel .carousel-item {
  text-align: center;
  overflow: hidden;
}

.carousel .carousel-item .btn i {
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}
.carousel-control-prev, .carousel-control-next {
  height: 44px;
  width: 44px;
  background: none;
  margin: auto 0;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.8);
}
.carousel-control-prev i, .carousel-control-next i {
  font-size: 36px;
  position: absolute;
  top: 50%;
  display: inline-block;
  margin: -19px 0 0 0;
  z-index: 5;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  font-weight: bold;
}
.carousel-control-prev i {
  margin-left: -3px;
}
.carousel-control-next i {
  margin-right: -3px;
}
.carousel-indicators {
  /* bottom: -50px; */
}
.carousel-indicators li, .carousel-indicators li.active {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 4px;
  border: none;
}
.carousel-indicators li {
  background: #ababab;
}
.carousel-indicators li.active {
  background: #555;
}

.product-carousel-container {
  padding: 2rem 0rem 3rem 0rem;
}

@media (min-width: 992px) {
  .product-carousel-container {
    padding: 2rem 5.15rem 3rem 5.15rem;
  }
  /*.product-carousel-title {*/
  /*  margin-left: -5.15rem;*/
  /*}*/
}

.product-carousel-title{
  font-size: 32px;
}

@media (max-width: 768px) {
  .product-carousel-title{
    font-size: 20px;
  }
}

/* Product Card */
/* .product-card-name {
  font-family: 'Varela Round', sans-serif;
} */

.product-card-img {
  min-width: 100%;
  height: 235px;
  display: inline-block;
}
.btn-out-of-stock {
  width: 100%;
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #ffc2c2;
  color: #b71c1c;
  border-color: #b71c1c;
  padding: 6px 15px;
  margin-top: 5px;
  text-decoration: none;
  cursor: not-allowed;
}
.btn-in-stock {
  width: 100%;
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  background: #272727;
  padding: 6px 15px;
  margin-top: 5px;
}
.btn-in-stock:hover {
  background: #020202;
}
.product-thumb-wrapper {
  margin: 5px;
  text-align: left;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
  border-radius: 20px;
}
.serandesco-product-thumb-wrapper{
  margin: 5px;
  text-align: left;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
  border-radius: 20px;
  padding: 10px 10px 0 10px;
  border: 1px solid rgba(0, 0, 0, 0.50);
}
.serandisco-price{
  text-align: center;
  font-weight: 600;
  font-size: 15px;
}
.buy-ticket-card{
  display: flex;
  width: 100%;
  border: none;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #271B75, #4431c1);
  padding: 10px 0;
  border-radius: 20px;
  color: white;
  text-decoration: none;
  font-weight: 600;
}
.buy-ticket-card:hover{
 color: white;
  text-decoration: none;
  background: linear-gradient(0deg, #1a1354, #271B75); /* Darker shades on hover */

}
.product-card-wishlist {
  position: absolute;
  top: 10px;
  right: 10px;
}

.product-img-box {
  position: relative;
  /*width: 100%;*/
  /*height: 100%;*/
}

.product-img{
  border-radius: 20px !important; /* Ensure border radius is applied */
  object-fit: cover;
}

.product-thumb-content {
  padding: 15px 0 ;
  font-size: 13px;
}

.product-variant-radio-content{
  width: auto;
  font-size: 12px;
  display: flex;
}
.product-variant-radio-input{
   color: black;
   height: 1rem;
   width:  1rem;
   margin-right: 5px;
   accent-color: black;
}

.grey-link-text{
  color: grey;
  font-weight: bold;
  text-decoration: underline;
}

/*Main Sponsor Banner*/

.main-sponsor-container {
  padding-top: 16px;
  padding-bottom: 46px;
}

@media (min-width: 576px) {
  .main-sponsor-container {
    padding-top: 41px;
    padding-bottom: 103px;
  }
}

.main-sponsor-banner-title {
  font-size: 16px;
  margin-bottom: 18px;
}

@media (min-width: 576px) {
  .main-sponsor-banner-title {
    font-size: 32px;
    margin-bottom: 51px;
  }
}

.main-sponsor-banner-img-container {
  margin-right: 5%;
  margin-left: 5%;
  height: fit-content;
}

@media (min-width: 576px) {
  .main-sponsor-banner-img-container {
    margin-left: 10%;
    margin-right: 10%;
    height: auto;
  }
}

.main-sponsor-banner-img {
  /*height: 200px;*/
}

@media (min-width: 576px) {
  .main-sponsor-banner-img {
    height: auto;
  }
}

/*Hero Banner*/

.hero-banner-container {
  position: relative;
  width: 100%;
  margin-bottom: 50px;

}

.hero-banner-btn-container {
  position: absolute;
  bottom:-40px;
  left: 50%;
  transform: translateX(-50%);
  /*background-color: Blue;*/
  width: 350px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid white;
  background: linear-gradient(0deg, #271B75, #4431c1);
  transition: background-color 0.3s ease;
  border-radius: 50px;
  font-size: 40px;
  font-weight: 800;
}

.hero-banner-btn-container:hover {
  background: linear-gradient(0deg, #1a1354, #271B75); /* Darker shades on hover */
  cursor: pointer;
}

.hero-banner-btn {
 font-size: 20px;
  display: flex;
  justify-content: center;
}

.hero-banner-btn svg {
  font-size: 30px;
  margin-left: 10px;
}

.hero-banner-image{
  /*height: 75dvh;*/
}

/*@media (max-width: 768px) {*/
/*  .hero-banner-container {*/
/*    position: relative;*/
/*    width: 100%;*/
/*    margin-bottom: 50px;*/
/*  }*/

/*}*/
/*@media (max-width: 1200px) {*/
/*  .hero-banner-btn-container {*/
/*    position: absolute;*/
/*    bottom: -8%;*/
/*    left: 50%;*/
/*    transform: translateX(-50%);*/
/*    width: 350px;*/
/*    height: 70px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border: 3px solid white;*/
/*    border-radius: 50px;*/
/*    font-weight: 800;*/

/*  }*/
/*  .hero-banner-btn {*/
/*    font-size: 20px;*/
/*  }*/

/*  .hero-banner-btn svg {*/
/*    font-size: 20px;*/
/*    margin-left: 10px;*/
/*  }*/
/*}*/

/*@media (max-width: 991px) {*/
/*  .hero-banner-btn-container {*/
/*    position: absolute;*/
/*    bottom: -10%;*/
/*    left: 50%;*/
/*    transform: translateX(-50%);*/
/*    width: 350px;*/
/*    height: 70px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border: 3px solid white;*/
/*    border-radius: 50px;*/
/*    font-weight: 800;*/

/*  }*/
/*  .hero-banner-btn {*/
/*    font-size: 20px;*/
/*  }*/

/*  .hero-banner-btn svg {*/
/*    font-size: 20px;*/
/*    margin-left: 10px;*/
/*  }*/
/*}*/


/*@media (max-width: 768px) {*/
/*  .hero-banner-btn-container {*/
/*    position: absolute;*/
/*    bottom: 4%;*/
/*    left: 50%;*/
/*    transform: translateX(-50%);*/
/*    width: 200px;*/
/*    height: 50px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border: 3px solid white;*/
/*    border-radius: 50px;*/
/*    font-weight: 800;*/

/*  }*/
/*  .hero-banner-btn {*/
/*    font-size: 15px;*/
/*  }*/

/*  .hero-banner-btn svg {*/
/*    font-size: 20px;*/
/*    margin-left: 10px;*/
/*  }*/
/*}*/


@media (max-width: 576px) {
  .hero-banner-btn-container {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid white;
    border-radius: 50px;
    font-weight: 800;

  }
  .hero-banner-btn {
    font-size: 15px;
  }

  .hero-banner-btn svg {
    font-size: 20px;
    margin-left: 10px;
  }
}

.product-name-mobile-view {
  display: none;
}

@media (max-width: 1079px) {
  .product-name-mobile-view {
    display: flex;
  }
}

.product-name-web-view {
  display: flex;
}

@media (max-width: 1079px) {
  .product-name-web-view {
    display: none;
  }
}

.buy-ticket-mobile-view {
  display: none;
}

@media (max-width: 768px) {
  .buy-ticket-mobile-view {
    display: flex;
  }
}

.buy-ticket-web-view {
  display: flex;
}

@media (max-width: 768px) {
  .buy-ticket-web-view {
    display: none;
  }
}

.quantity-btn-web-view {
  display: flex;
}

@media (max-width: 1079px) {
  .quantity-btn-web-view {
    display: none;
  }
}

.quantity-btn-mobile-view {
  display: none;
}

@media (max-width: 1079px) {
  .quantity-btn-mobile-view {
    display: flex;
  }
}

.product-info-text{
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .product-info-text{
    font-size: 0.875rem;
  }
}

.brand-logo-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}

.brand-logo {
  object-fit: contain;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.brand-logo:hover {
  transform: scale(1.1);
}

.about-us-header{
  font-size: 2rem;
  font-weight: bold;
}

.about-us-title{
  font-size: 1.5rem;
  font-weight: bold;
  color:rgba(39, 27, 117, 1) ;
}

.about-us-highlight{
  font-size: 2rem;
  font-weight: bold;
  color:rgba(39, 27, 117, 1) ;
}

.about-us-text{
  font-size: 0.9rem;
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 0px !important;
}

@media (max-width: 991px) {
  .about-us-text{
    font-size: 0.8rem;
  }
  .about-us-title{
    font-size: 1rem;
  }
  .about-us-header{
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .about-us-title, .about-us-text{
    font-size: 0.8rem;
  }
  .about-us-header{
    font-size: 1.4rem;
  }
}


@media (max-width: 565px) {
  .about-us-text{
    font-size: 0.8rem;
  }
  .about-us-title{
    font-size: 1rem;
  }
  .about-us-header{
    font-size: 1.5rem;
    margin-bottom: 0rem;

  }
}

/*@media (max-width: 767px) {*/
/*.about-us-section2{*/
/*  display: flex !important;*/
/*  flex-direction: column !important;*/
/*}*/
/*}*/

.about-us-section1, .about-us-section2{
  margin-bottom: 5rem;
}

@media (max-width: 576px) {
  .about-us-section1, .about-us-section2{
    margin-bottom: 3rem;
  }
}

.about-us-image1{
  width: 100%;
  height: 18.75rem ;
}

@media (max-width: 576px) {
  .about-us-image1{
    width: auto;
    height: auto ;
  }
}

.about-us-image2{
  height: 20rem ;
}

@media (max-width: 576px) {
  .about-us-image2{
    width: auto;
    height: auto ;
  }
}


.section1-img,.section2-img,.section3-img,.section4-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-section3 {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}

.section3-img {
  flex: 0 0 33.33%; /* 3 images per row for large screens */
  max-width: 33.33%;
}

@media (max-width: 756px) {
  .section3-img {
    flex: 0 0 50%; /* 2 images per row for smaller screens */
    max-width: 50%;
  }

  @media (max-width: 576px) {
    .section3-img {
      flex: 0 0 50%; /* 1 image per row for very small screens */
      max-width: 50%;
    }
  }
}


.about-us-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #271B75, #4431c1);
  transition: background-color 0.3s ease;
  border-radius: 50px;

}

.about-us-btn-container:hover {
  background: linear-gradient(0deg, #1a1354, #271B75); /* Darker shades on hover */
  cursor: pointer;
}

.about-us-banner-btn {
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
}

.about-us-banner-btn svg {
  font-size: 20px;
  margin-left: 10px;
}

.product-view-buy-now-btn {
  background: linear-gradient(0deg, #271B75, #4431c1);
  transition: background-color 0.3s ease;

}

.product-view-buy-now-btn:hover {
  background: linear-gradient(0deg, #1a1354, #271B75); /* Darker shades on hover */
}

.place-order-btn {
  background: linear-gradient(0deg, #271B75, #4431c1);
  transition: background-color 0.3s ease;
}

.place-order-btn:hover {
  background: linear-gradient(0deg, #1a1354, #271B75); /* Darker shades on hover */
}



/* Grab Yours Page */
.grab-yours-page {
margin-top: 100px !important;
}

.grab-yours-page-main-img-container{
  margin-bottom: 40px;
  /*height: 472px;*/
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
  width: fit-content;
  /*border-radius: 10px;*/
  /*border-style: solid;*/
  /*border-color: black;*/
  overflow: hidden;
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  .grab-yours-page-main-img-container{
    margin-bottom: 20px;
    /*height: 472px;*/
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    width: 90%;
    /*border-radius: 10px;*/
    /*border-style: solid;*/
    /*border-color: black;*/
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

}

.desktop-only {
  display: none !important;
}
.mobile-only {
  display: block !important;
}

@media (min-width: 576px) {
  .desktop-only {
    display: block !important;
  }
  .mobile-only {
    display: none !important;
  }
}


.grab-yours-page-main-img {
  /*height: 472px;*/
  object-fit: cover;
}

.grab-yours-page-main-products-container {
  margin-right: 8%;
  margin-left: 8%;
  margin-bottom: 1.25rem;
  display: flex;
  gap: 3%;
  row-gap: 1.25rem;
  justify-content: center;
  flex-wrap: wrap;
}
.grab-yours-page-product-link{
  width: 20%;
  text-decoration: none !important;
}

.grab-yours-page-product-card {
  text-decoration: none;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.50);
  background: #F9F9F9;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.10);
  /*width: 15.625rem;*/
  /*width: 20%;*/
  /*height: 300px;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grab-yours-page-product-card-img-container{
  overflow: hidden;
  width: 90%;
  border-radius: 10px;
  aspect-ratio: 1/1;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.grab-yours-page-product-card-img {
  border-radius: 10px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.grab-yours-page-product-card-img:hover {
  transform: scale(1.1);
}

.grab-yours-page-product-card-name {
  color: rgba(0, 0, 0, 0.70);
  text-align: center;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;

}

.grab-yours-page-product-card-price {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0.625rem;
  text-decoration: none;

}

.grab-yours-page-product-card-btn {
  display: flex;
  width: 86%;
  height: 2.125rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: linear-gradient(0deg, #271B75, #4431c1);
  color: white;
  border-radius: 50px;
  text-align: center;
  font-family: Montserrat;
  /*font-size: 1rem;*/
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.625rem;
  border: none;
}

.grab-yours-page-product-card-btn:hover {
  background: linear-gradient(0deg, #1a1354, #271B75); /* Darker shades on hover */
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 768px) {
  .grab-yours-page-main-products-container {
    justify-content: space-between; /* Optional: Adjust spacing */
  }

  .grab-yours-page-product-card-img-container{
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
    aspect-ratio: 1/1;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .grab-yours-page-product-link{
    width: calc(50% - 1.5%);
  }

  .grab-yours-page-product-card {

  }

  .grab-yours-page-product-card-name {
    display: none;
  }

  .grab-yours-page-product-card-price {
    display: none;
  }

  .grab-yours-page-product-card-btn {
    display: none;
  }
}


/* order failed page CSS */

.order-failed-image-container {
  margin-bottom: 35px;
}

.order-failed-message {
  padding-top: 20px;
  padding-bottom: 20px;
}

.order-failed-buttons-container {
  display: flex;
  justify-content: center;
  gap: 5%;
  margin-bottom: 35px;
  width: auto;
}

.order-failed-contact-btn {
  background-color: #ffffff;
  border-radius: 50px;
  border: 0.5px solid rgba(0, 0, 0, 0.40);
  font-weight: 550;
  font-size: 1rem;
  height: 60px;
  width: 28rem;
  /*border: none;*/
}

.order-failed-tryagain-btn {
  border-radius: 50px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(39, 27, 117, 0.00) 8.73%), linear-gradient(90deg, rgba(39, 27, 117, 0.00) 87.55%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(39, 27, 117, 0.00) 69.59%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(39, 27, 117, 0.00) 73.72%, rgba(0, 0, 0, 0.20) 100%), #322390;
  color: white;
  font-weight: 550;
  font-size: 1rem;
  height: 60px;
  width: 28rem;
  border: none;
}

.order-failed-footer-details {
  background-color: rgba(198, 198, 198, 0.37);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  border-radius: 10px;

  padding-top: 30px;
  padding-bottom: 30px;

}

@media (max-width: 991px) {

  .order-failed-contact-btn {
    width: 20rem;
    height: 45px;
  }

  .order-failed-tryagain-btn {
    width: 20rem;
    height: 45px;
  }
}

@media (max-width: 770px) {

  .order-failed-buttons-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .order-failed-contact-btn {
    margin-bottom: 15px;
  }

  .order-failed-image-container {
    margin-bottom: 20px;
  }

}

.order-fail-details-txt{
  font-size: 0.875rem;
  color: black;
}

/* order success page CSS */

.order-success-footer-details {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
}

.order-success-downloadTicket-btn {
  border-radius: 50px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(39, 27, 117, 0.00) 8.73%), linear-gradient(90deg, rgba(39, 27, 117, 0.00) 87.55%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(39, 27, 117, 0.00) 69.59%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(39, 27, 117, 0.00) 73.72%, rgba(0, 0, 0, 0.20) 100%), #322390;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  height: 50px;
  width: 20rem;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;

}

@media (max-width: 680px) {

  .order-success-downloadTicket-btn {
    width: 90%;
  }

}

.order-summary-container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  /*background-color: rgba(159, 159, 159, 0.15);*/
}

.order-summary-small-container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 110%;
  /*background-color: rgba(159, 159, 159, 0.15);*/
}

.order-summary-image {
  width: 4rem;
  aspect-ratio: 1/1;
}

.order-summary-last-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.order-summary-quantity-div {
  display: flex;
  /*flex-direction: column-reverse;*/
  justify-content: flex-end;
  margin-left: 90px;
}

.order-summary-price-container {
  display: flex;
  justify-content: end;
  align-items: center;
}

.form-mobile-heading{
  color: #271B75;
  font-size: 20px;
  font-weight: 600;
}


@media (max-width: 1280px) {

  .order-summary-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }

  .order-summary-small-container {
    display: flex;
    /*align-items: center;*/
  }

  .order-summary-quantity-div {
    margin-left: 2px;
  }

  .order-summary-last-section {
    justify-content: space-between;
  }

}
@media (max-width: 768px) {

  .card-body {
    padding: 0.25rem;
  }
}


.modal-overlay {
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 998;
  overflow: hidden;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
}

.modal-contents {
  position: relative;
  width: 70%;
  max-height: 90vh;
  background: linear-gradient(145deg, #ffffff, #f0f0f5);
  padding: 0 20px 20px !important;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow-y: auto;
  }

  @media (max-width: 768px) {
    .modal-contents {
      width: 85%;
      max-height: 80vh;
      padding: 0 20px 20px;
      border-radius: 0 0 12px 12px;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
      text-align: center;
    }
  }

  @media (min-width: 1200px) {
    .modal-contents {
      width: 60%;
    }
  }

  @media (max-height: 600px) {
    .modal-contents {
      max-height: 70vh;
      padding: 15px;
    }
  }

.ticket-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.download-title {
  font-size: 1.75rem;
  color: #fff;
  font-weight: bold;
}

.download-option-text {
  font-size: 0.9rem;
  font-weight: 500;
}

@media (max-width: 768px), (max-height: 600px) {
  .download-title {
    font-size: 1.5rem;
    color: #fff;
    font-weight: bold;
  }

  .download-option-text {
    font-size: 0.75rem;
    font-weight: 500;
  }
}

@media (max-width: 565px) {
  .download-title {
    font-size: 1.3rem;
    color: #fff;
    font-weight: bold;
  }
  .download-option-text {
    font-size: 0.75rem;
  }
}

.download-header {
  width: 70%;
  height: 10vh;
  margin: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.20) 0%, rgba(39, 27, 117, 0.00) 8.73%), linear-gradient(90deg, rgba(39, 27, 117, 0.00) 87.55%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(39, 27, 117, 0.00) 69.59%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(39, 27, 117, 0.00) 73.72%, rgba(0, 0, 0, 0.20) 100%), #322390;
  color: white;
  padding: 10px 30px;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .download-header {
    width: 85%;
    height: 10vh;
    padding: 20px;
  }
}

@media (max-width: 565px) {
  .download-header {
    height: 10vh;
    padding: 15px;
  }
}

@media (min-width: 1200px) {
  .download-header {
    width: 60%;
  }
}

.order-downloadzip-btn {
  height: 50px;
  border-radius: 8px;
  background: linear-gradient(to right, #322390, #4a47a3);
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.3s;
}

.order-downloadzip-btn:hover {
  transform: scale(1.1);
}

.download-ticket-btn {
  height: 7vh;
  min-width: 150px;
  border-style: solid;
  border-width: 1px;
  border-color: #271B75;
  border-radius: 8px;
  background: #eceff4;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-ticket-btn:hover {
  background: #322390;
  color: white;
  transform: scale(1.05);
}

@media (max-width: 768px), (max-height: 600px) {
  .download-ticket-btn {
    height: 6vh;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 565px) {
  .download-ticket-btn {
    height: 6vh;
    min-width: 120px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 345px) {
  .download-ticket-btn {
    height: 6vh;
    min-width: 100px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media (max-height: 1500px) {
  .download-ticket-btn {
    height: 6vh;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 768px) {
  .download-ticket-btn {
    height: 6vh;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background: #eceff4;
    transition: all 0.3s ease;
  }

  .download-ticket-btn:active {
    background: #322390;
    color: white;
    transform: scale(1.05);
  }
}



